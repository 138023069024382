import React, { createRef, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import gsap from 'gsap';
import SquareAnimationNoMargin from 'components/squareAnimationNoMargin/squareAnimationNoMargin';
import CirclePurpleRightFast from 'components/circle/circlePurpleRightFast';
import CirclePurpleRight from 'components/circle/circlePurpleRight';
import CirclePurpleRightSlow from 'components/circle/circlePurpleRightSlow';
import {
  BackgroundWrapper,
  SvgWrapper,
  HexagonWrapper,
  Hexagon,
  HexagonText,
  HexagonLineWrapper,
  HexagonRightLine,
  HexagonCircle,
  HexagonBottomLine,
  SectionTextWrapper,
  LineWrapper,
  TopLine,
  RightLine,
  Dot,
  TextWrapper,
  TextBox,
  TextWord,
  ContentWrapper,
  SquareBoxWrapper,
  RatchetWrapper,
  FirstRatchet,
  SecondRatchet,
  ThirdRatchet,
  FourthRatchet,
  FifthRatchet,
  SixthRatchet,
} from './previewDesktop.styled';

const PreviewDesktop = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "background.png" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  const hexagonRef = createRef();
  const hexagonRightLineRef = createRef();
  const hexagonBottomLineRef = createRef();
  const hexagonDotRef = createRef();
  const hexagonTextRef = createRef();

  const firstTopLineRef = createRef();
  const firstTopDotRef = createRef();
  const firstBottomDotRef = createRef();
  const firstRightLineRef = createRef();
  const secondTopLineRef = createRef();
  const secondBottomDotRef = createRef();
  const secondRightLineRef = createRef();
  const thirdTopLineRef = createRef();
  const thirdBottomDotRef = createRef();
  const thirdRightLineRef = createRef();

  const firstTextRef = createRef();
  const secondTextRef = createRef();
  const thirdTextRef = createRef();
  const fourthTextRef = createRef();
  const fifthTextRef = createRef();
  const sixthTextRef = createRef();

  const tl = gsap.timeline();

  const firstTextTl = gsap.timeline({ paused: true });

  const secondTextTl = gsap.timeline({ paused: true });

  const thirdTextTl = gsap.timeline({ paused: true });

  function OnFirstLineComplete() {
    firstTextTl.play();
  }

  function OnSecondLineComplete() {
    secondTextTl.play();
  }

  function OnThirdLineComplete() {
    thirdTextTl.play();
  }

  useEffect(() => {
    firstTextTl
      .set([firstTextRef.current, secondTextRef.current], { opacity: 1 })
      .addLabel('textStart')
      .staggerFrom(
        firstTextRef.current.children,
        0.5,
        { opacity: 0, x: -20, y: -20 },
        0.15,
        'textStart'
      )
      .staggerFrom(
        secondTextRef.current.children,
        0.5,
        { opacity: 0, x: -20, y: -20 },
        0.15,
        'textStart'
      );
    secondTextTl
      .set([thirdTextRef.current, fourthTextRef.current], { opacity: 1 })
      .addLabel('textStart')
      .staggerFrom(
        thirdTextRef.current.children,
        0.5,
        { opacity: 0, x: -20, y: -20 },
        0.15,
        'textStart'
      )
      .staggerFrom(
        fourthTextRef.current.children,
        0.5,
        { opacity: 0, x: -20, y: -20 },
        0.15,
        'textStart'
      );
    thirdTextTl
      .set([fifthTextRef.current, sixthTextRef.current], { opacity: 1 })
      .addLabel('textStart')
      .staggerFrom(
        fifthTextRef.current.children,
        0.5,
        { opacity: 0, x: -20, y: -20 },
        0.15,
        'textStart'
      )
      .staggerFrom(
        sixthTextRef.current.children,
        0.5,
        { opacity: 0, x: -20, y: -20 },
        0.15,
        'textStart'
      );
    tl.set(
      [
        hexagonRef.current,
        hexagonRightLineRef.current,
        hexagonBottomLineRef.current,
        hexagonDotRef.current,
        hexagonTextRef.current,
        firstTopLineRef.current,
        firstTopDotRef.current,
        firstBottomDotRef.current,
        firstRightLineRef.current,
        secondTopLineRef.current,
        secondBottomDotRef.current,
        secondRightLineRef.current,
        thirdTopLineRef.current,
        thirdBottomDotRef.current,
        thirdRightLineRef.current,
        firstTextRef.current,
        secondTextRef.current,
        thirdTextRef.current,
        fourthTextRef.current,
        fifthTextRef.current,
        sixthTextRef.current,
      ],
      { opacity: 0 }
    )
      .fromTo(
        hexagonDotRef.current,
        0.25,
        {
          opacity: 1,
          scale: 0,
          transformOrigin: 'center center',
        },
        { scale: 1, transformOrigin: 'center center' }
      )
      .fromTo(
        hexagonRightLineRef.current,
        0.25,
        { opacity: 1, scaleX: 0, transformOrigin: 'right' },
        { scaleX: 1, transformOrigin: 'right' }
      )
      .fromTo(
        hexagonRef.current,
        0.25,
        { opacity: 1, scale: 0, transformOrigin: 'center right' },
        { scale: 1, transformOrigin: 'center right' }
      )
      .set(hexagonTextRef.current, { opacity: 1 })
      .staggerFrom(
        hexagonTextRef.current.children,
        0.3,
        { opacity: 0, x: -20, y: -20 },
        0.1
      )
      .fromTo(
        hexagonBottomLineRef.current,
        0.25,
        { opacity: 1, scale: 0, transformOrigin: 'top center' },
        { scale: 1, transformOrigin: 'top center' }
      )
      .fromTo(
        firstTopDotRef.current,
        0.25,
        { opacity: 1, scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .fromTo(
        firstTopLineRef.current,
        0.25,
        { opacity: 1, scale: 0, transformOrigin: 'top center' },
        { scale: 1, transformOrigin: 'top center' }
      )
      .fromTo(
        firstBottomDotRef.current,
        0.25,
        { opacity: 1, scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .fromTo(
        firstRightLineRef.current,
        0.25,
        { opacity: 1, scaleX: 0, transformOrigin: 'left' },
        { scaleX: 1, transformOrigin: 'left', OnFirstLineComplete }
      )
      .fromTo(
        secondTopLineRef.current,
        0.25,
        { opacity: 1, scale: 0, transformOrigin: 'top center' },
        { scale: 1, transformOrigin: 'top center' }
      )
      .fromTo(
        secondBottomDotRef.current,
        0.25,
        { opacity: 1, scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .fromTo(
        secondRightLineRef.current,
        0.25,
        { opacity: 1, scaleX: 0, transformOrigin: 'left' },
        { scaleX: 1, transformOrigin: 'left', OnSecondLineComplete }
      )
      .fromTo(
        thirdTopLineRef.current,
        0.25,
        { opacity: 1, scale: 0, transformOrigin: 'top center' },
        { scale: 1, transformOrigin: 'top center' }
      )
      .fromTo(
        thirdBottomDotRef.current,
        0.25,
        { opacity: 1, scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .fromTo(
        thirdRightLineRef.current,
        0.25,
        { opacity: 1, scaleX: 0, transformOrigin: 'left' },
        { scaleX: 1, transformOrigin: 'left', OnThirdLineComplete }
      );
  });

  return (
    <>
      <BackgroundWrapper Tag="div" fluid={image.sharp.fluid}>
        <HexagonWrapper>
          <SvgWrapper />
          <Hexagon ref={hexagonRef}>
            <HexagonText ref={hexagonTextRef}>
              <TextWord>Co&nbsp;</TextWord>
              <TextWord>nas&nbsp;</TextWord>
              <TextWord>wyróżnia?&nbsp;</TextWord>
            </HexagonText>
          </Hexagon>
          <HexagonLineWrapper>
            <HexagonRightLine ref={hexagonRightLineRef} />
            <HexagonCircle ref={hexagonDotRef} />
          </HexagonLineWrapper>
          <HexagonBottomLine ref={hexagonBottomLineRef} />
        </HexagonWrapper>
      </BackgroundWrapper>
      <ContentWrapper>
        <SectionTextWrapper>
          <LineWrapper>
            <TopLine ref={firstTopLineRef} />
            <Dot ref={firstTopDotRef} />
            <Dot ref={firstBottomDotRef} isBottom />
            <RightLine ref={firstRightLineRef} />
          </LineWrapper>
          <TextWrapper>
            <TextBox />
            <TextBox ref={firstTextRef}>
              <TextWord>Oferta&nbsp;</TextWord>
              <TextWord>dopasowana&nbsp;</TextWord>
              <TextWord>do&nbsp;</TextWord>
              <TextWord>różnego&nbsp;</TextWord>
              <TextWord>typu&nbsp;</TextWord>
              <TextWord>klientów.&nbsp;</TextWord>
            </TextBox>
          </TextWrapper>
        </SectionTextWrapper>
        <SectionTextWrapper>
          <LineWrapper>
            <TopLine ref={secondTopLineRef} />
            <Dot ref={secondBottomDotRef} isBottom />
            <RightLine ref={secondRightLineRef} />
          </LineWrapper>
          <TextWrapper>
            <TextBox ref={secondTextRef}>
              <TextWord>Jesteśmy&nbsp;</TextWord>
              <TextWord>elastyczni&nbsp;</TextWord>
              <TextWord>i&nbsp;</TextWord>
              <TextWord>łączymy&nbsp;</TextWord>
              <TextWord>nasze&nbsp;</TextWord>
              <TextWord>doświadczenie,&nbsp;</TextWord>
              <TextWord>fachową&nbsp;</TextWord>
              <TextWord>wiedzę&nbsp;</TextWord>
              <TextWord>z&nbsp;</TextWord>
              <TextWord>oczekiwaniami&nbsp;</TextWord>
              <TextWord>przedsiębiorców&nbsp;</TextWord>
              <TextWord>tak&nbsp;</TextWord>
              <TextWord>by&nbsp;</TextWord>
              <TextWord>stworzyć&nbsp;</TextWord>
              <TextWord>produkt&nbsp;</TextWord>
              <TextWord>idealny.&nbsp;</TextWord>
            </TextBox>
            <TextBox ref={thirdTextRef}>
              <TextWord>Cenimy&nbsp;</TextWord>
              <TextWord>Twój&nbsp;</TextWord>
              <TextWord>czas.&nbsp;</TextWord>
            </TextBox>
          </TextWrapper>
        </SectionTextWrapper>
        <SectionTextWrapper>
          <LineWrapper>
            <TopLine ref={thirdTopLineRef} />
            <Dot ref={thirdBottomDotRef} isBottom />
            <RightLine ref={thirdRightLineRef} />
          </LineWrapper>
          <TextWrapper>
            <TextBox ref={fourthTextRef}>
              <TextWord>A&nbsp;</TextWord>
              <TextWord>więc&nbsp;</TextWord>
              <TextWord>każdy&nbsp;</TextWord>
              <TextWord>projekt&nbsp;</TextWord>
              <TextWord>wykonujemy&nbsp;</TextWord>
              <TextWord>z&nbsp;</TextWord>
              <TextWord>największym&nbsp;</TextWord>
              <TextWord>zaangażowaniem&nbsp;</TextWord>
              <TextWord>i&nbsp;</TextWord>
              <TextWord>w&nbsp;</TextWord>
              <TextWord>jak&nbsp;</TextWord>
              <TextWord>najkrótszym&nbsp;</TextWord>
              <TextWord>czasie.&nbsp;</TextWord>
            </TextBox>
            <TextBox ref={fifthTextRef}>
              <TextWord>Zapewniamy&nbsp;</TextWord>
              <TextWord>kompleksową&nbsp;</TextWord>
              <TextWord>usługę.&nbsp;</TextWord>
            </TextBox>
          </TextWrapper>
        </SectionTextWrapper>
        <SectionTextWrapper>
          <LineWrapper />
          <TextWrapper>
            <TextBox ref={sixthTextRef}>
              <TextWord>Od&nbsp;</TextWord>
              <TextWord>projektu&nbsp;</TextWord>
              <TextWord>graficznego&nbsp;</TextWord>
              <TextWord>aż&nbsp;</TextWord>
              <TextWord>po&nbsp;</TextWord>
              <TextWord>dostarczenie&nbsp;</TextWord>
              <TextWord>gotowych&nbsp;</TextWord>
              <TextWord>produktów.&nbsp;</TextWord>
            </TextBox>
          </TextWrapper>
        </SectionTextWrapper>
        <SquareBoxWrapper>
          <SquareAnimationNoMargin />
        </SquareBoxWrapper>
        <RatchetWrapper>
          <FirstRatchet>
            <CirclePurpleRightFast />
          </FirstRatchet>
          <SecondRatchet>
            <CirclePurpleRight />
          </SecondRatchet>
          <ThirdRatchet>
            <CirclePurpleRight />
          </ThirdRatchet>
          <FourthRatchet>
            <CirclePurpleRightSlow />
          </FourthRatchet>
          <FifthRatchet>
            <CirclePurpleRightFast />
          </FifthRatchet>
          <SixthRatchet>
            <CirclePurpleRight />
          </SixthRatchet>
        </RatchetWrapper>
      </ContentWrapper>
    </>
  );
};

export default PreviewDesktop;
