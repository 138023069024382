import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

export const BackgroundWrapper = styled(BackgroundImage)`
  background-size: cover;
  width: 100%;
  height: 80px;
  position: relative;
`;

export const HexagonWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Hexagon = styled.div`
  width: 104px;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: 15px;
  left: calc(50% - 52px);

  ::after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    bottom: -30px;
    left: 0;
    border-top: 30px solid ${({ theme }) => theme.colors.white};
    border-left: 52px solid transparent;
    border-right: 52px solid transparent;
  }

  ::before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    top: -30px;
    left: 0;
    border-bottom: 30px solid ${({ theme }) => theme.colors.white};
    border-left: 52px solid transparent;
    border-right: 52px solid transparent;
  }
`;

export const HexagonText = styled.p`
  font-size: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.colors.grey};
  text-align: center;
  margin-top: 10px;
`;

export const TextWord = styled.span`
  display: inline-block;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-flow: column;
  margin-top: 50px;
`;
export const ContentItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
`;
export const ContentDot = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.blue};
  margin: 20px 0;
`;
export const ContentTitleWrapper = styled.p`
  margin: 10px 60px;
  text-align: center;
  font-size: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.colors.grey};

  @media (min-width: 750px) {
    margin: 10px 250px;
  }
`;
export const ContentTextWrapper = styled.p`
  margin: ${props =>
    props.isLast ? '10px 40px 40px 40px' : '10px 40px 10px 40px'};
  text-align: center;
  font-size: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.colors.grey};

  @media (min-width: 750px) {
    margin: ${props =>
      props.isLast ? '10px 250px 40px 250px' : '10px 250px 10px 250px'};
  }
`;
