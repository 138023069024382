import React, { useEffect, createRef } from 'react';
import gsap from 'gsap';
import SquareAnimation from 'components/squareAnimation/squareAnimation';
import SvgHome from 'components/svgHome/svgHome';
import {
  SectionWrapper,
  TitleWrapper,
  TextBox,
  TextWord,
  SquareBoxWrapper,
  SvgWrapper,
} from './title.styled';

const Title = () => {
  const textRef = createRef();

  const titleTl = gsap.timeline();

  const textTl = gsap.timeline({ repeat: -1, repeatDelay: 2, paused: true });

  function OnTextComplete() {
    textTl.play();
  }

  useEffect(() => {
    textTl
      .to(textRef.current.children[3], 0.2, {
        color: '#942985',
        scale: 1.1,
      })
      .to(textRef.current.children[3], 0.2, {
        color: '#7c7c7c',
        scale: 1,
      })
      .to(textRef.current.children[4], 0.2, {
        color: '#942985',
        scale: 1.1,
      })
      .to(textRef.current.children[4], 0.2, {
        color: '#7c7c7c',
        scale: 1,
      })
      .to(textRef.current.children[5], 0.2, {
        color: '#942985',
        scale: 1.1,
      })
      .to(textRef.current.children[5], 0.2, {
        color: '#7c7c7c',
        scale: 1,
      })
      .to(textRef.current.children[6], 0.2, {
        color: '#942985',
        scale: 1.1,
      })
      .to(textRef.current.children[6], 0.2, {
        color: '#7c7c7c',
        scale: 1,
      });
    titleTl
      .set(textRef.current, { opacity: 1 })
      .staggerFrom(
        textRef.current.children,
        0.45,
        { y: -50, opacity: 0 },
        0.015
      )
      .to(textRef.current, 0.015, { OnTextComplete });
  });

  return (
    <SectionWrapper>
      <TitleWrapper>
        <TextBox ref={textRef}>
          <TextWord>Fusion&nbsp;</TextWord>
          <TextWord>of&nbsp;</TextWord>
          <TextWord>our&nbsp;</TextWord>
          <TextWord>experience&nbsp;</TextWord>
          <TextWord>and&nbsp;</TextWord>
          <TextWord>your&nbsp;</TextWord>
          <TextWord>vision&nbsp;</TextWord>
        </TextBox>
      </TitleWrapper>
      <SquareBoxWrapper>
        <SquareAnimation />
      </SquareBoxWrapper>
      <SvgWrapper>
        <SvgHome />
      </SvgWrapper>
    </SectionWrapper>
  );
};

export default Title;
