import React from 'react';

import Layout from 'components/layout/layout';
import Title from 'templates/homeSection/title/title';
import Preview from 'templates/homeSection/preview/preview';
import FooterMobile from 'components/footerMobile/footerMobile';
import SEO from 'components/SEO/SEO';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Title />
    <Preview />
    <FooterMobile />
  </Layout>
);

export default IndexPage;
