import styled from 'styled-components';

export const SectionWrapper = styled.div`
  width: 100%;
  display: ${props => (props.isVisible ? 'block' : 'none')};

  @media (min-width: 850px) {
    display: ${props => (props.isVisible ? 'none' : 'block')};
  }
`;
