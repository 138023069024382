import React, { useEffect, createRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import gsap from 'gsap';
import { ScrollScene } from 'scrollscene';
import {
  BackgroundWrapper,
  HexagonWrapper,
  Hexagon,
  HexagonText,
  TextWord,
  ContentWrapper,
  ContentItem,
  ContentDot,
  ContentTitleWrapper,
  ContentTextWrapper,
} from './previewMobile.styled';

const PreviewMobile = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "background.png" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  const animationStartRef = createRef();

  const hexagonRef = createRef();
  const hexagonTextRef = createRef();

  const firstDotRef = createRef();
  const secondDotRef = createRef();
  const thirdDotRef = createRef();

  const firstTitleRef = createRef();
  const secondTitleRef = createRef();
  const thirdTitleRef = createRef();

  const firstTextRef = createRef();
  const secondTextRef = createRef();
  const thirdTextRef = createRef();

  const tl = gsap.timeline();

  const contentTl = gsap.timeline({ paused: true });

  const firstTitleTl = gsap.timeline({
    repeat: -1,
    repeatDelay: 1,
    paused: true,
  });

  const secondTitleTl = gsap.timeline({
    repeat: -1,
    repeatDelay: 1,
    paused: true,
  });

  const thirdTitleTl = gsap.timeline({
    repeat: -1,
    repeatDelay: 1,
    paused: true,
  });

  function onFirstTitleComplete() {
    firstTitleTl.play();
  }

  function onSecondTitleComplete() {
    secondTitleTl.play();
  }

  function onThirdTitleComplete() {
    thirdTitleTl.play();
  }

  useEffect(() => {
    firstTitleTl
      .to(firstTitleRef.current.children[0], 0.2, {
        color: '#942985',
        scale: 1.1,
      })
      .to(firstTitleRef.current.children[0], 0.2, {
        color: '#7c7c7c',
        scale: 1,
      })
      .to(firstTitleRef.current.children[1], 0.2, {
        color: '#942985',
        scale: 1.1,
      })
      .to(firstTitleRef.current.children[1], 0.2, {
        color: '#7c7c7c',
        scale: 1,
      })
      .to(firstTitleRef.current.children[2], 0.2, {
        color: '#942985',
        scale: 1.1,
      })
      .to(firstTitleRef.current.children[2], 0.2, {
        color: '#7c7c7c',
        scale: 1,
      })
      .to(firstTitleRef.current.children[3], 0.2, {
        color: '#942985',
        scale: 1.1,
      })
      .to(firstTitleRef.current.children[3], 0.2, {
        color: '#7c7c7c',
        scale: 1,
      })
      .to(firstTitleRef.current.children[4], 0.2, {
        color: '#942985',
        scale: 1.1,
      })
      .to(firstTitleRef.current.children[4], 0.2, {
        color: '#7c7c7c',
        scale: 1,
      })
      .to(firstTitleRef.current.children[5], 0.2, {
        color: '#942985',
        scale: 1.1,
      })
      .to(firstTitleRef.current.children[5], 0.2, {
        color: '#7c7c7c',
        scale: 1,
      });
    secondTitleTl
      .to(secondTitleRef.current.children[0], 0.2, {
        color: '#942985',
        scale: 1.1,
      })
      .to(secondTitleRef.current.children[0], 0.2, {
        color: '#7c7c7c',
        scale: 1,
      })
      .to(secondTitleRef.current.children[1], 0.2, {
        color: '#942985',
        scale: 1.1,
      })
      .to(secondTitleRef.current.children[1], 0.2, {
        color: '#7c7c7c',
        scale: 1,
      })
      .to(secondTitleRef.current.children[2], 0.2, {
        color: '#942985',
        scale: 1.1,
      })
      .to(secondTitleRef.current.children[2], 0.2, {
        color: '#7c7c7c',
        scale: 1,
      });
    thirdTitleTl
      .to(thirdTitleRef.current.children[0], 0.2, {
        color: '#942985',
        scale: 1.1,
      })
      .to(thirdTitleRef.current.children[0], 0.2, {
        color: '#7c7c7c',
        scale: 1,
      })
      .to(thirdTitleRef.current.children[1], 0.2, {
        color: '#942985',
        scale: 1.1,
      })
      .to(thirdTitleRef.current.children[1], 0.2, {
        color: '#7c7c7c',
        scale: 1,
      })
      .to(thirdTitleRef.current.children[2], 0.2, {
        color: '#942985',
        scale: 1.1,
      })
      .to(thirdTitleRef.current.children[2], 0.2, {
        color: '#7c7c7c',
        scale: 1,
      });
    tl.set(
      [
        hexagonRef.current,
        firstDotRef.current,
        secondDotRef.current,
        thirdDotRef.current,
        firstTitleRef.current,
        secondTitleRef.current,
        thirdTitleRef.current,
        firstTextRef.current,
        secondTextRef.current,
        thirdTextRef.current,
      ],
      {
        opacity: 0,
      }
    )
      .fromTo(
        hexagonRef.current,
        0.25,
        { scale: 0, transformOrigin: 'center center', opacity: 1 },
        { scale: 1, transformOrigin: 'center center' }
      )
      .staggerFrom(
        hexagonTextRef.current.children,
        0.3,
        { opacity: 0, x: -20, y: -20 },
        0.1
      );
    contentTl
      .fromTo(
        firstDotRef.current,
        0.25,
        { scale: 0, transformOrigin: 'center center', opacity: 1 },
        { scale: 1, transformOrigin: 'center center' }
      )
      .set(firstTitleRef.current, { opacity: 1 })
      .staggerFrom(
        firstTitleRef.current.children,
        0.3,
        { opacity: 0, x: -20, y: -20 },
        0.1
      )
      .set(firstTextRef.current, { opacity: 1, onFirstTitleComplete })
      .staggerFrom(
        firstTextRef.current.children,
        0.3,
        { opacity: 0, x: -20, y: -20 },
        0.1
      )
      .fromTo(
        secondDotRef.current,
        0.25,
        { scale: 0, transformOrigin: 'center center', opacity: 1 },
        { scale: 1, transformOrigin: 'center center' }
      )
      .set(secondTitleRef.current, { opacity: 1 })
      .staggerFrom(
        secondTitleRef.current.children,
        0.3,
        { opacity: 0, x: -20, y: -20 },
        0.1
      )
      .set(secondTextRef.current, { opacity: 1, onSecondTitleComplete })
      .staggerFrom(
        secondTextRef.current.children,
        0.3,
        { opacity: 0, x: -20, y: -20 },
        0.1
      )
      .fromTo(
        thirdDotRef.current,
        0.25,
        { scale: 0, transformOrigin: 'center center', opacity: 1 },
        { scale: 1, transformOrigin: 'center center' }
      )
      .set(thirdTitleRef.current, { opacity: 1 })
      .staggerFrom(
        thirdTitleRef.current.children,
        0.3,
        { opacity: 0, x: -20, y: -20 },
        0.1
      )
      .set(thirdTextRef.current, { opacity: 1, onThirdTitleComplete })
      .staggerFrom(
        thirdTextRef.current.children,
        0.3,
        { opacity: 0, x: -20, y: -20 },
        0.1
      );

    const scrollscene = new ScrollScene({
      triggerElement: animationStartRef.current,
      reverse: false,
      gsap: {
        timeline: contentTl,
      },
    });
  });

  return (
    <>
      <BackgroundWrapper Tag="div" fluid={image.sharp.fluid}>
        <HexagonWrapper>
          <Hexagon ref={hexagonRef}>
            <HexagonText ref={hexagonTextRef}>
              <TextWord ref={animationStartRef}>Co&nbsp;</TextWord>
              <TextWord>nas&nbsp;</TextWord>
              <TextWord>wyróżnia?&nbsp;</TextWord>
            </HexagonText>
          </Hexagon>
        </HexagonWrapper>
      </BackgroundWrapper>
      <ContentWrapper>
        <ContentItem>
          <ContentDot ref={firstDotRef} />
          <ContentTitleWrapper ref={firstTitleRef}>
            <TextWord>Oferta&nbsp;</TextWord>
            <TextWord>dopasowana&nbsp;</TextWord>
            <TextWord>do&nbsp;</TextWord>
            <TextWord>różnego&nbsp;</TextWord>
            <TextWord>typu&nbsp;</TextWord>
            <TextWord>klientów.&nbsp;</TextWord>
          </ContentTitleWrapper>
          <ContentTextWrapper ref={firstTextRef}>
            <TextWord>Jesteśmy&nbsp;</TextWord>
            <TextWord>elastyczni&nbsp;</TextWord>
            <TextWord>i&nbsp;</TextWord>
            <TextWord>łączymy&nbsp;</TextWord>
            <TextWord>nasze&nbsp;</TextWord>
            <TextWord>doświadczenie,&nbsp;</TextWord>
            <TextWord>fachową&nbsp;</TextWord>
            <TextWord>wiedzę&nbsp;</TextWord>
            <TextWord>z&nbsp;</TextWord>
            <TextWord>oczekiwaniami&nbsp;</TextWord>
            <TextWord>przedsiębiorców&nbsp;</TextWord>
            <TextWord>tak&nbsp;</TextWord>
            <TextWord>by&nbsp;</TextWord>
            <TextWord>stworzyć&nbsp;</TextWord>
            <TextWord>produkt&nbsp;</TextWord>
            <TextWord>idealny.&nbsp;</TextWord>
          </ContentTextWrapper>
        </ContentItem>
        <ContentItem>
          <ContentDot ref={secondDotRef} />
          <ContentTitleWrapper ref={secondTitleRef}>
            <TextWord>Cenimy&nbsp;</TextWord>
            <TextWord>Twój&nbsp;</TextWord>
            <TextWord>czas.&nbsp;</TextWord>
          </ContentTitleWrapper>
          <ContentTextWrapper ref={secondTextRef}>
            <TextWord>A&nbsp;</TextWord>
            <TextWord>więc&nbsp;</TextWord>
            <TextWord>każdy&nbsp;</TextWord>
            <TextWord>projekt&nbsp;</TextWord>
            <TextWord>wykonujemy&nbsp;</TextWord>
            <TextWord>z&nbsp;</TextWord>
            <TextWord>największym&nbsp;</TextWord>
            <TextWord>zaangażowaniem&nbsp;</TextWord>
            <TextWord>i&nbsp;</TextWord>
            <TextWord>w&nbsp;</TextWord>
            <TextWord>jak&nbsp;</TextWord>
            <TextWord>najkrótszym&nbsp;</TextWord>
            <TextWord>czasie.&nbsp;</TextWord>
          </ContentTextWrapper>
        </ContentItem>
        <ContentItem>
          <ContentDot ref={thirdDotRef} />
          <ContentTitleWrapper ref={thirdTitleRef}>
            <TextWord>Zapewniamy&nbsp;</TextWord>
            <TextWord>kompleksową&nbsp;</TextWord>
            <TextWord>usługę.&nbsp;</TextWord>
          </ContentTitleWrapper>
          <ContentTextWrapper ref={thirdTextRef} isLast>
            <TextWord>Od&nbsp;</TextWord>
            <TextWord>projektu&nbsp;</TextWord>
            <TextWord>graficznego&nbsp;</TextWord>
            <TextWord>aż&nbsp;</TextWord>
            <TextWord>po&nbsp;</TextWord>
            <TextWord>dostarczenie&nbsp;</TextWord>
            <TextWord>gotowych&nbsp;</TextWord>
            <TextWord>produktów.&nbsp;</TextWord>
          </ContentTextWrapper>
        </ContentItem>
      </ContentWrapper>
    </>
  );
};

export default PreviewMobile;
