import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

export const BackgroundWrapper = styled(BackgroundImage)`
  background-size: cover;
  width: 100%;
  height: 184px;
  position: relative;
`;

export const ContentContainer = styled.div`
  width: 60%;
  height: 100%;
  display: flex;

  @media (max-width: 950px) {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
`;

export const ContentWrapper = styled.div`
  width: calc(100% - 50px);
  margin-left: 50px;
  height: auto;
  display: flex;
  justify-content: flex-start;
  flex-flow: column;
  position: relative;
`;

export const SvgWrapper = styled.div`
  width: 330px;
  position: absolute;
  top: -50px;
  right: -300px;
`;

export const HexagonWrapper = styled.div`
  display: flex;
  width: calc(100% - 350px);
  height: 100%;
  position: relative;
`;

export const Hexagon = styled.div`
  width: 104px;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: 60px;
  left: 50px;

  ::after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    bottom: -30px;
    left: 0;
    border-top: 30px solid ${({ theme }) => theme.colors.white};
    border-left: 52px solid transparent;
    border-right: 52px solid transparent;
  }

  ::before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    top: -30px;
    left: 0;
    border-bottom: 30px solid ${({ theme }) => theme.colors.white};
    border-left: 52px solid transparent;
    border-right: 52px solid transparent;
  }
`;

export const HexagonText = styled.p`
  font-size: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.colors.grey};
  text-align: center;
  margin-top: 10px;
`;

export const HexagonLineWrapper = styled.div`
  height: 30px;
  width: 50%;
  position: absolute;
  top: 75px;
  left: 154px;
  display: flex;
  align-items: center;

  @media (min-width: 1000px) {
    width: 60%;
  }

  @media (min-width: 1200px) {
    width: 70%;
  }

  @media (min-width: 1350px) {
    width: 60%;
  }

  @media (min-width: 1600px) {
    width: 70%;
  }

  @media (min-width: 1800px) {
    width: 75%;
  }
`;

export const HexagonRightLine = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  height: 3px;
  width: calc(100% - 30px);
`;

export const HexagonCircle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const HexagonBottomLine = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 3px;
  height: 35px;
  position: absolute;
  top: 149px;
  left: 100px;
`;

export const SectionTextWrapper = styled.div`
  width: 614px;
  height: 200px;
  display: flex;
  justify-content: flex-start;
`;

export const LineWrapper = styled.div`
  width: 53px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

export const TopLine = styled.div`
  width: 3px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.blue};
`;

export const RightLine = styled.div`
  height: 3px;
  width: 560px;
  background-color: ${({ theme }) => theme.colors.blue};
  position: absolute;
  top: 199px;
  left: 61px;
`;

export const Dot = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.blue};
  position: absolute;
  top: ${props => (props.isBottom ? '190px' : '-10px')};
  left: 41px;
`;

export const TextWrapper = styled.div`
  width: 570px;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
`;

export const TextBox = styled.p`
  width: 500px;
  font-size: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.colors.grey};
  margin: 20px 0 20px 20px;
`;

export const TextWord = styled.span`
  display: inline-block;
`;

export const SquareBoxWrapper = styled.div`
  width: 60%;
  height: 40px;
  margin-bottom: 60px;
  margin-left: 80px;
`;

export const RatchetWrapper = styled.div`
  width: 650px;
  height: 740px;
  position: absolute;
  top: 150px;
  left: 630px;
`;

export const FirstRatchet = styled.div`
  position: absolute;
  width: 50px;
  top: 20px;
  left: 200px;
`;

export const SecondRatchet = styled.div`
  position: absolute;
  width: 150px;
  top: 100px;
  left: 250px;
`;

export const ThirdRatchet = styled.div`
  position: absolute;
  width: 150px;
  top: 150px;
  left: 450px;
`;

export const FourthRatchet = styled.div`
  position: absolute;
  width: 350px;
  top: 300px;
  left: 230px;
`;

export const FifthRatchet = styled.div`
  position: absolute;
  width: 50px;
  top: 440px;
  left: 100px;
`;

export const SixthRatchet = styled.div`
  position: absolute;
  width: 150px;
  top: 550px;
  left: 100px;
`;
