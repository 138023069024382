import styled from 'styled-components';

export const Svg = styled.svg`
  width: 264px;
  height: 305px;
  position: absolute;
  top: -100px;
  left: 20px;

  @media (min-width: 850px) {
    width: 100%;
    height: 100%;
    position: static;
  }

  .st0 {
    fill: #eeeae8;
    enable-background: new;
  }
  .st1 {
    opacity: 0.2;
  }
  .st2 {
    fill: #ffffff;
  }
  .st3 {
    fill: url(#SVGID_1_);
  }
  .st4 {
    enable-background: new;
  }
  .st5 {
    fill: #e6f0f4;
  }
  .st6 {
    fill: #c1deea;
  }
  .st7 {
    fill: #cbdbe7;
  }
  .st8 {
    fill: #bad0e0;
  }
  .st9 {
    opacity: 0.1;
    fill: #00586c;
    enable-background: new;
  }
  .st10 {
    fill: #f6ccb3;
  }
  .st11 {
    fill: #ff8484;
  }
  .st12 {
    fill: #0081a7;
  }
  .st13 {
    opacity: 0.1;
    enable-background: new;
  }
  .st14 {
    fill: #906357;
  }
  .st15 {
    fill: #243551;
  }
  .st16 {
    opacity: 0.1;
    fill: #6c90b1;
    enable-background: new;
  }
  .st17 {
    fill: #f7b7a9;
  }
  .st18 {
    fill: #fce6e2;
  }
  .st19 {
    fill: #1b283d;
  }
  .st20 {
    fill: #74828b;
  }
  .st21 {
    fill: #0d6189;
  }
  .st22 {
    opacity: 0.4;
    fill: #ffffff;
    enable-background: new;
  }
  .st23 {
    opacity: 0.702;
    fill: #ffffff;
    enable-background: new;
  }
  .st24 {
    opacity: 0.8;
  }
  .st25 {
    opacity: 0.3;
    fill: #ffffff;
    enable-background: new;
  }
  .st26 {
    fill: #e7f1f4;
  }
  .st27 {
    fill: #cfdfe5;
  }
  .st28 {
    opacity: 0.15;
    fill: #00586c;
    enable-background: new;
  }
  .st29 {
    fill: #d9e0eb;
  }
  .st30 {
    fill: #f6b6a8;
  }
  .st31 {
    fill: #412928;
  }
  .st32 {
    opacity: 0.1;
    fill: #6c8fb0;
    enable-background: new;
  }
  .st33 {
    opacity: 0.15;
    fill: #243551;
    enable-background: new;
  }
  .st34 {
    fill: #de9777;
  }
  .st35 {
    opacity: 0.4;
    fill: #69b5cf;
    enable-background: new;
  }
  .st36 {
    opacity: 0.5;
  }
  .st37 {
    opacity: 0.6;
    fill: #69b5cf;
    enable-background: new;
  }
  .st38 {
    opacity: 0.4;
  }
  .st39 {
    stop-color: #ffffff;
  }
  .st40 {
    stop-color: #aac4d8;
  }
`;
