import styled from 'styled-components';

export const SectionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  margin-top: 50px;
  width: 100%;
  height: 60%;
  position: relative;

  @media (min-width: 850px) {
    margin-left: 50px;
    width: calc(100% - 50px);
    height: 200px;
    margin-top: 0px;
    flex-flow: row;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: wrap;
  width: 100%;
  height: 100%;

  @media (min-width: 850px) {
    width: 50%;
  }
`;

export const TextBox = styled.div`
  display: inline-block;
  width: 80%;
  margin-top: 50px;
  height: 80px;
  color: ${({ theme }) => theme.colors.grey};
  font-size: ${({ theme }) => theme.font.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  opacity: 0;

  @media (max-width: 900px) {
    width: 60%;
    text-align: center;
    margin: auto;
    padding: 0px 30px;
  }

  @media (max-width: 700px) {
    font-size: ${({ theme }) => theme.font.xl};
  }

  @media (max-width: 500px) {
    width: 100%;
    text-align: center;
    font-size: ${({ theme }) => theme.font.m};
  }
`;

export const TextWord = styled.span`
  display: inline-block;
`;

export const SquareBoxWrapper = styled.div`
  width: calc(100% - 400px);
  height: 40px;
  display: none;

  @media (min-width: 850px) {
    display: flex;
  }
`;

export const SvgWrapper = styled.div`
  width: 300px;
  height: 335px;
  position: relative;

  @media (min-width: 850px) {
    position: absolute;
    z-index: 10;
    top: 190px;
    right: 40px;
  }

  @media (min-width: 1100px) {
    top: 135px;
    right: 40px;
  }

  @media (min-width: 1000px) {
    @media (min-height: 1300px) {
      top: 125px;
    }
  }
`;
