import React from 'react';
import PreviewDesktop from 'components/previewDesktop/previewDesktop';
import PreviewMobile from 'components/previewMobile/previewMobile';
import { SectionWrapper } from './preview.styled';

const Preview = () => {
  return (
    <>
      <SectionWrapper>
        <PreviewDesktop />
      </SectionWrapper>
      <SectionWrapper isVisible>
        <PreviewMobile />
      </SectionWrapper>
    </>
  );
};

export default Preview;
