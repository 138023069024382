import styled from 'styled-components';

export const SquareBox = styled.div`
  width: calc(100% - 176.5px);
  margin-left: 166.5px;
  margin-right: 10px;
  height: 40px;
  overflow: hidden;
`;

export const Square = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
`;
