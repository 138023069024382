import React, { useEffect, createRef } from 'react';
import gsap from 'gsap';
import { Svg } from './svgHome.styled';

const svg = createRef();
const flatUp = createRef();
const flatDown = createRef();
const flatLeft = createRef();
const peopleDown = createRef();
const peopleUp = createRef();
const Interface = createRef();
const InterfaceOne = createRef();
const InterfaceTwo = createRef();
const InterfaceThree = createRef();
const speechBalloon = createRef();
const speechBalloonDots = createRef();
const Shape = createRef();
const Box = createRef();
const Cube = createRef();
const peopleLeft = createRef();
const peopleRight = createRef();
const Square1 = createRef();
const Square2 = createRef();

const SvgHome = () => {
  const tl = gsap.timeline();
  const InterfaceMove = gsap.timeline({
    repeat: -1,
    repeatDelay: 2,
    yoyo: true,
    paused: true,
  });
  const CubeMove = gsap.timeline({
    repeat: -1,
    repeatDelay: 2,
    yoyo: true,
    paused: true,
  });
  const SpeechDotsMove = gsap.timeline({ repeat: -1, paused: true });

  const ShapeMove = gsap.timeline({
    repeat: -1,
    repeatDelay: 1,
    yoyo: true,
    paused: true,
  });
  const SquareMove = gsap.timeline({ repeat: -1 });

  function onInterfaceComplete() {
    InterfaceMove.play();
  }
  function onCubeComplete() {
    CubeMove.play();
  }
  function onDotsComplete() {
    SpeechDotsMove.play();
  }
  function onShapeComplete() {
    ShapeMove.play();
  }

  useEffect(() => {
    SquareMove.to(Square1.current, 1, { y: -5 })
      .addLabel('steps')
      .to(Square1.current, 1, { y: 0 }, 'steps+=0')
      .to(Square2.current, 1, { y: -5 }, 'steps+=0')
      .to(Square2.current, 1, { y: 0 });
    InterfaceMove.to(InterfaceOne.current, 0.45, { y: 0 })
      .to(InterfaceTwo.current, 0.45, { x: 0 })
      .to(InterfaceThree.current, 0.45, { y: 0 });
    CubeMove.to(Cube.current, 1, { opacity: 0 });
    SpeechDotsMove.to(speechBalloonDots.current.children[0], 0.25, { y: -5 })
      .addLabel('faze1')
      .to(speechBalloonDots.current.children[1], 0.25, { y: -5 }, 'faze1+=0')
      .to(speechBalloonDots.current.children[0], 0.25, { y: 0 }, 'faze1+=0')
      .addLabel('faze2')
      .to(speechBalloonDots.current.children[2], 0.25, { y: -5 }, 'faze2+=0')
      .to(speechBalloonDots.current.children[1], 0.25, { y: 0 }, 'faze2+=0');
    ShapeMove.to(Shape.current, 1, { x: 10, y: -10 });
    ShapeMove.to(Shape.current, 1, { x: 20, y: 20 });
    tl.set(svg.current, { opacity: 1 })
      .set(
        [
          flatUp.current,
          flatDown.current,
          flatLeft.current,
          peopleDown.current,
          peopleUp.current,
          Interface.current,
          speechBalloon.current,
          speechBalloonDots.current,
          Shape.current,
          Box.current,
          Cube.current,
          peopleLeft.current,
          peopleRight.current,
        ],
        { opacity: 0 }
      )
      .addLabel('flatFadeIn')
      .to(flatUp.current, 0.5, { opacity: 1 }, 'flatFadeIn+=0')
      .to(flatDown.current, 0.5, { opacity: 1 }, 'flatFadeIn+=0.5')
      .to(peopleDown.current, 0.5, { opacity: 1 })
      .to(Interface.current, 0.5, { opacity: 1 })
      .to(InterfaceOne.current, 0.45, { y: -10 })
      .to(InterfaceTwo.current, 0.45, { x: 10 })
      .to(InterfaceThree.current, 0.45, { y: -10, onInterfaceComplete })
      .to(flatLeft.current, 0.5, { opacity: 1 })
      .to(Box.current, 0.5, { opacity: 1 })
      .to(peopleLeft.current, 0.5, { opacity: 1 })
      .to(Cube.current, 0.5, { opacity: 1 })
      .to(Cube.current, 0.5, { opacity: 1, onCubeComplete })
      .to(peopleUp.current, 0.5, { opacity: 1 })
      .to(speechBalloon.current, 0.5, { opacity: 1, x: -20, y: -10 })
      .to(speechBalloonDots.current, 0.001, { x: -20, y: -10 })
      .to(speechBalloonDots.current, 0.5, { opacity: 1, onDotsComplete })
      .to(peopleRight.current, 0.5, { opacity: 1 })
      .to(Shape.current, 0.5, { opacity: 1, onShapeComplete });
  });

  return (
    <Svg
      version="1.1"
      id="Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 584 648"
      classNameName="st39"
      ref={svg}
    >
      <g>
        <polygon
          className="st0"
          points="33.7,172 33.7,468.6 290.5,616.8 547.3,468.6 547.3,172 290.5,23.8 	"
        />
        <g>
          <g id="romb_x5F_4_1_" className="st1" ref={Square1}>
            <path
              className="st2"
              d="M222.9,421.9L144,376.4c-2.6-1.5-6.8-1.5-9.4,0l-78.4,45.5c-2.6,1.5-2.6,3.9,0,5.5l78.9,45.5
            c2.6,1.5,6.8,1.5,9.4,0l78.4-45.5C225.5,425.8,225.4,423.4,222.9,421.9z"
            />
          </g>
          <g id="romb_x5F_3_1_" className="st1" ref={Square2}>
            <path
              className="st2"
              d="M532.7,377.1l-78.9-45.5c-2.6-1.5-6.8-1.5-9.4,0l-78.4,45.5c-2.6,1.5-2.6,3.9,0,5.5l78.9,45.5
            c2.6,1.5,6.8,1.5,9.4,0l78.4-45.5C535.3,381,535.3,378.6,532.7,377.1z"
            />
          </g>
          <g id="Podloga_x5F_2_1_" ref={flatUp}>
            <linearGradient
              id="SVGID_1_"
              gradientUnits="userSpaceOnUse"
              x1="271.7483"
              y1="72.0812"
              x2="266.6895"
              y2="-213.2328"
              gradientTransform="matrix(1 0 0 -1 0 368.1102)"
            >
              <stop offset="0" className="st39" />
              <stop offset="1" className="st40" />
            </linearGradient>
            <path
              className="st3"
              d="M509.5,366.4L305.9,248.8c-2.9-1.7-7.5-1.7-10.4,0L30.8,399.3c-2.9,1.7-2.9,4.4,0,6l203.6,117.6
            c2.9,1.7,7.5,1.7,10.4,0l264.6-150.5C512.4,370.7,512.4,368,509.5,366.4z"
            />
          </g>
          <g id="Podłoga_x5F_1_1_" ref={flatDown}>
            <g>
              <g className="st4">
                <g>
                  <path
                    className="st5"
                    d="M509.5,349.3L305.9,231.8c-2.9-1.7-7.5-1.7-10.4,0L30.8,382.3c-2.9,1.7-2.9,4.4,0,6l203.6,117.6
                  c2.9,1.7,7.5,1.7,10.4,0l264.6-150.5C512.4,353.7,512.4,351,509.5,349.3z"
                  />
                </g>
                <g>
                  <path
                    className="st6"
                    d="M162.8,432.5l89.5,51.3c1,0.6,2.8,0.6,3.8,0l88.4-50l-89.1-52.2C255.3,381.6,161.7,431.9,162.8,432.5z"
                  />
                </g>
                <path
                  className="st7"
                  d="M509.5,355.4L244.8,505.9c-2.9,1.7-7.5,1.7-10.4,0L30.8,388.3c-1.4-0.8-2.1-1.9-2.1-3v7.6
                c0,1.1,0.7,2.2,2.1,3l203.6,117.5c2.9,1.7,7.5,1.7,10.4,0l264.6-150.5c1.5-0.8,2.2-1.9,2.2-3v-7.6
                C511.7,353.4,511,354.5,509.5,355.4z"
                />
              </g>
            </g>
          </g>
          <g id="Podest_x5F_lewy_1_" ref={flatLeft}>
            <g>
              <g className="st4">
                <g>
                  <path
                    className="st2"
                    d="M247.5,323.9l-80-48.1c-2.9-1.7-7.5-1.7-10.4,0l-126,73.4c-2.9,1.7-2.9,4.4,0,6l80,48.1
                  c2.9,1.7,7.5,1.7,10.4,0l126-73.4C250.3,328.3,250.4,325.6,247.5,323.9z"
                  />
                </g>
                <path
                  className="st7"
                  d="M247.5,330l-126,73.4c-2.9,1.7-7.6,1.7-10.4,0l-80-48.2c-1.4-0.8-2.1-1.9-2.1-3v33.1c0,1.1,0.7,2.2,2.1,3
                l80,48.1c2.9,1.7,7.5,1.7,10.4,0l126-73.4c1.5-0.8,2.2-1.9,2.2-3v-33.1C249.6,328,248.9,329.1,247.5,330z"
                />
                <path
                  className="st8"
                  d="M247.5,330l-126,73.4c-2.1,1.2-5.1,1.5-7.6,1v33.1c2.6,0.5,5.6,0.2,7.6-1l126-73.4c1.5-0.8,2.2-1.9,2.2-3
                V327C249.6,328,248.9,329.1,247.5,330z"
                />
              </g>
            </g>
          </g>
          <g id="Ludzik_x5F_dol_1_" ref={peopleDown}>
            <path
              className="st9"
              d="M277.7,440.5c-2.9-1.4-6.3-1.6-9.5-1.7l-0.6-2.4c-1.9,3-5.6,4.4-9.1,5.1c-3.5,0.7-7.2,1.1-10.3,3
            c-3,1.9-5.2,5.9-3.6,9.1c1.9,3.8,7.1,3.9,11.3,3.4c4.8-0.5,9.6-1,14.4-1.5c2.6-0.3,5.2-0.5,7.6-1.6c2.4-1.1,4.4-3.2,4.8-5.7
            C283,445,280.6,441.9,277.7,440.5z"
            />
            <g>
              <path
                className="st10"
                d="M299.3,356.4l-4.9,1.7c0,0-0.4,2.3,1.8,2.9c2.2,0.6,2.1,0.7,2.1,0.7l0.3,0.7l1.8-0.1
              c1.3-0.1,2.4-1,2.7-2.3l0.4-1.5l1.7,0.2c0,0,2.1,0.7,2.7,0.8c1,0.1,1.1-0.6,1.1-0.6l-4.1-1.9L299.3,356.4z"
              />
              <path
                className="st2"
                d="M277.6,441.3l-6.9-3.9l-5.1-2.5l-1.3,1.6c0,0-1.3,4.3-0.1,5c1.2,0.6,6.2,2.2,8.4,3.3
              c2.1,1.1,5.9,0.9,6.8,0.2c0.3-0.2,0.4-0.7,0.2-1.2C279.4,442.7,278.6,441.8,277.6,441.3z"
              />
              <g>
                <path
                  className="st11"
                  d="M280.6,355.4c0,0-4.6-18.4-8.3-20.9c-3.2-2.2-6.3-1.1-6.3-1.1s4.7,10.7,2.4,11.1
                c-0.7,0.1-1.4,0.1-2.1,0.1c0,0,6.3,16.5,11.4,18.1c4.3,1.3,17.8-1.8,17.8-1.8s-0.7-1.2,0.2-3.5L280.6,355.4z"
                />
                <path
                  className="st2"
                  d="M259.9,447.5l-5.6-4.5l-5.4,0.8l-1,3.6c0.1,0.8,1.1,2.1,1.9,2.4l9,3.7c0,0,5.5,1,5.5-2.3l-0.1-0.2
                C263.9,449.6,260.9,448.3,259.9,447.5z"
                />
                <path
                  className="st12"
                  d="M265.3,374.4l-17.1,2.1l-0.1,37.5l0.6,30.1c0,0,2.8,2.1,7.4,0.3l4.5-32.2l1.3-7.5
                c0.4,8.6,1.9,33.4,1.9,33.4c3.3,1.8,7.5-0.5,7.5-0.5l2.1-20.5l0.6-10.7l1.6-27.3l-10.3,0.4V374.4L265.3,374.4L265.3,374.4z"
                />
                <path
                  className="st13"
                  d="M248.1,414.2l0.6,30.1c0,0,0.5,0.4,1.4,0.7c-1.8-12.9,1.1-27.1,2.4-39.5c1.1-10.4,1.4-21,6.3-30.1
                l-10.6,1.3L248.1,414.2z"
                />
                <path
                  className="st13"
                  d="M262.4,410.4c0,0.3-0.1,0.5-0.2,0.7c0.6,10.7,1.6,27,1.6,27c3.3,1.8,7.5-0.5,7.5-0.5l0,0
                c-2.1-4.9-7.4-41.8-8.5-46C262.5,397.7,262.5,403.9,262.4,410.4z"
                />
                <path
                  className="st11"
                  d="M274.5,339.1c-1.8-5.2-3.9-7.3-9.6-7.4h-3.5l-3.9,1.1c0,0-3.9-0.8-10.4,5.2c-2.7,2.5,2.6,27.6,2.6,27.6
                l-1.5,11c0,0,2.5,7.8,13.7,6.6c8.8-0.9,13.8-4,13.8-4s1.4-3.1,1.4-10.8c0-2.8-1.8-15.7-1.8-15.7S275.6,342,274.5,339.1z"
                />
                <path
                  className="st13"
                  d="M252.2,364.8c-1.3-4.8-1.5-9.9-1.5-15.1c0-1.4,0.3-2.5,0.8-3.5c-0.3-1.9-0.8-3.6-1.9-4.3
                c-1.3-0.9-2.3-2.1-2.8-3.3c-1.8,4.5,3,27,3,27l-1.5,11c0,0,2.5,6.9,11.8,6.7C255.1,378.9,251.6,371.4,252.2,364.8z"
                />
                <path
                  className="st10"
                  d="M258,325.6l-1.5,7.2c0.2,2.4,3,4.7,5.5,4.5s4.3-2.3,4.1-4.8l-0.6-7.2L258,325.6z"
                />
                <path
                  className="st13"
                  d="M258.7,325.5c0,0.2,0.1,0.4,0.1,0.7c0.1,3.9,2.7,8.9,6.7,8.4c0.1-0.1,0.2-0.1,0.3-0.2
                c0.2-0.6,0.3-1.2,0.3-1.9l-0.6-7.2L258.7,325.5z"
                />
                <path
                  className="st10"
                  d="M256.7,319.4l0.2,6.9c-0.3,3.7,5.7,6.2,6.1,6.3c3,0.9,6.2-0.7,6.9-4.4c0.5-2.8-0.2-10-0.2-10L256.7,319.4
                z"
                />
                <path
                  className="st14"
                  d="M268.4,314.2c-4.8-3.6-9.3-2.1-12.7,1.5c-1.6,1.7-0.9,5.6-0.6,7.4c0.7,4.1,2.8,6.4,2.8,6.4l0.9-6.3
                l0.6,1.3l1.1-0.7c0,0-0.3-2.7-0.2-3.1c0.2-1.5,9.2-1,9.2-1S273.6,318.1,268.4,314.2z"
                />
                <path
                  className="st10"
                  d="M256.6,323.3c-0.3,1.6,0.6,3.5,2,3l0.3-3.1C258.8,323.2,257,321.1,256.6,323.3z"
                />
                <g>
                  <path
                    className="st10"
                    d="M241.2,382.2l-0.4,7.1l0.6,2.5l1.6,1.5c0,0,1,0.8,1.1,1c0.3,0.6,1.3,0.3,1.2-0.4l-0.2-2.2
                  c0,0,0.8-2.4,0.9-2.4l0,0l0.1-0.3l-0.3,2.1c-0.2,1.8,1.1,1.8,1.1,1.8l0.9-6l-1.1-4.7H241.2z"
                  />
                </g>
                <path
                  className="st11"
                  d="M251.4,336.6c-5.1-1-7.6,7.3-7.6,7.3l-3.1,17.4l0.4,21.3c0,0,1.9,1.4,5.8-0.2l2.1-18.9l5-15.9
                C254,347.5,256.4,337.6,251.4,336.6z"
                />
                <path
                  className="st13"
                  d="M243.9,383.1c0.7,0,1.6-0.3,3-0.8l2.1-18.9l5-15.9c0,0,0.5-1.9,0.6-4c-0.4,3.4-2.4,7-3.8,9.9
                C246.6,361.8,244.8,373.6,243.9,383.1z"
                />
              </g>
              <g>
                <g className="st4">
                  <g>
                    <path
                      className="st15"
                      d="M273.5,320.3l-9.2,3.2c-0.3,0.1-0.7,0.5-0.7,0.8l-0.8,4.3c-0.1,0.4,0.2,0.5,0.5,0.4l3.7-1.3
                    c0.2-0.1,0.4-0.2,0.5-0.4c0,0,0.4-0.5,0.6-0.5c0.2-0.1,0.5,0.2,0.5,0.2c0.1,0.1,0.2,0.1,0.4,0l3.6-1.3
                    c0.3-0.1,0.7-0.5,0.7-0.9l0.8-4.3C274.1,320.4,273.8,320.2,273.5,320.3z"
                    />
                  </g>
                  <path
                    className="st2"
                    d="M270,317.3l-9.2,3.2c-0.3,0.1-0.7,0.5-0.7,0.8l-0.1,4.5c0,0.2,0,0.3,0.1,0.4l2.8,2.8
                  c-0.1-0.1-0.1-0.2-0.1-0.4l0.8-4.3c0.1-0.3,0.4-0.7,0.7-0.8l9.2-3.2c0.2-0.1,0.3,0,0.4,0l-3.5-3
                  C270.3,317.3,270.2,317.3,270,317.3z"
                  />
                  <path
                    className="st15"
                    d="M263.7,322.3c-1.1,0.6-0.8,1-1.1,1.9c-0.3,1.2-0.7,3.8-0.8,3.8l1,1c-0.1-0.1-0.1-0.2-0.1-0.4l0.8-4.3
                  c0.1-0.3,0.4-0.7,0.7-0.8l9.2-3.2c0.2-0.1,0.3,0,0.4,0l-1.5-1.3C269.5,320.1,266.7,321.4,263.7,322.3z"
                  />
                </g>
              </g>
              <polygon
                className="st2"
                points="254.8,322.2 260.2,324.7 260.9,323.1 254.6,320.6 				"
              />
            </g>
          </g>
          <g id="Ludziki_x5F_gora_1_" ref={peopleUp}>
            <path
              className="st16"
              d="M280.2,265.3c-6.1,0.5-19,3.9-20.1,11.4c-1.5,10.4,27.6,2.3,30.3-6.2C292.2,264.8,284.3,264.6,280.2,265.3z
            "
            />
            <path
              className="st16"
              d="M334.6,294.9c-6.1,0.5-19,3.9-20.1,11.4c-1.5,10.4,27.6,2.3,30.3-6.2C346.6,294.5,338.8,294.2,334.6,294.9z
            "
            />
            <g>
              <g>
                <g>
                  <g>
                    <path
                      className="st17"
                      d="M294.8,204l-0.5-2.1c-4.1-1.1-4.3,1.8-4.3,1.8l1.4,2.1c-0.1,0.5,0.7,1.8,0.7,1.8l0.6,1.2l-0.1,3.6
                    c-0.6,0.5-0.4,1-0.4,1l1.6-0.2l-0.8,0.1c-0.5,0.6,0.2,1.2,0.2,1.2l0.9-0.5c-0.5,0.7-0.4,1.2,1,0.9c1.5-0.3,2.4-6.3,2.3-6.9
                    C297.4,207.4,294.8,204,294.8,204z"
                    />
                    <path
                      className="st18"
                      d="M289.9,203.9l1,1.6c0,0,1.9-1.7,4.2-1.6l-0.4-1.6C294.7,202.4,291.9,201.5,289.9,203.9z"
                    />
                    <path
                      className="st15"
                      d="M321.4,304.4c0,0-5.5-0.6-8.5-0.6c-2.1,0-3.6,2.4-1.8,3.9l11.3,3.4c0,0,4,1.1,5.1-1.6l-0.3-4.1
                    L321.4,304.4z"
                    />
                    <path
                      className="st15"
                      d="M282.1,166.6c-2.5-4-6.6-3.8-6.6-3.8s3.5,11,1.1,11.2c-0.7,0-1.4,0-2.1-0.1l5.3,7.8l4.4,9.5l5.8,13.4
                    c0,0,2.5-2.2,5.1-1.9l-6-19.1C289.2,183.5,284.1,169.8,282.1,166.6z"
                    />
                    <path
                      className="st15"
                      d="M273,273.9l-5.3-5.3l-7.4,1.1v3.1c0,0.9,0.5,1.7,1.2,2.1l9.3,5.2c0,0,4.2,1.1,5.7-2v-0.2
                    C276.2,276.3,274,275,273,273.9z"
                    />
                    <path
                      className="st15"
                      d="M286.8,265.9l-4.6-4.6l-6.4,1v2.7c0,0.8,0.4,1.5,1.1,1.9l4.4,1.5c0,0,4.7,3.2,6.7,2.5
                    C289.6,270.1,291.2,268.2,286.8,265.9z"
                    />
                    <path
                      className="st19"
                      d="M282.2,218c-0.1-2.9,0-13.7,0-13.7c-2.4,1.4-3.4,0-5.7,1.1c-6.1,3-20.6,1.4-20.7,1.4l3.1,36.4l1.4,27.2
                    c5.4,2.7,8.1-1.5,8.1-1.5l1.6-43.7l2.1-0.9l3.6,38.4c7.5,2.3,7-1,7-1S282.7,229.5,282.2,218z"
                    />
                    <path
                      className="st2"
                      d="M279.6,188.2c0.2-7.5,0.7-14.5-0.7-21.9c-2-0.6-3.9-1.3-5.6-2.5c-0.5-0.4-0.8-0.8-1-1.3h-3.5l-4.1,0.7
                    l-6.6,4.3c-3.1,1.7-4.8,4.3-4.6,7.9l4.2,31c0,0,0.6,5,11.6,3.5c6-0.8,9.4-2.5,11.1-3.8C279.7,200.2,279.4,194.2,279.6,188.2z
                    "
                    />
                    <path
                      className="st15"
                      d="M280.2,168.6c-1.1-3.5-2.2-6.2-7-6.1c3.6,6.4,3.1,18.4,2.9,22.5c-0.2,4.2-1.7-2.1-1.7,2.1
                    c0,5.6,0,11.1,0,16.7c1.5,0.7,2,4.9,3.5,6.4c2.7,2.9,4.9-2,4.9-2l-1.6-18.7C281.1,189.5,281.3,172,280.2,168.6z"
                    />
                    <path
                      className="st15"
                      d="M275.6,183.5c0,0.2,0,0.4,0,0.4s-7-12.7-8-14.3c-1.3-1.9-2.5-3.9-3.7-5.8l-5.7,3.7
                    c-3.1,1.7-4.8,4.3-4.6,7.9l3,36.7c0,0,1.1,2.8,10,3.7c4.2,0.4,10.9-3.1,11.1-5.7c-1.1-7.9-1.6-18.5-2.1-26.5
                    C275.7,183.7,275.6,183.6,275.6,183.5z"
                    />
                    <path
                      className="st17"
                      d="M253.6,219.7c1,0.1,1.3,0.2,2.3,0.4c0.2,0,0.5,0,0.7,0c0.6,0,1.2,0,1.8,0.1l0,0l0.1-7.7l-4.8,1.4
                    L253.6,219.7z"
                    />
                    <path
                      className="st17"
                      d="M257.2,219.1l1.2,0.9l-0.1,2.1c0,1.9,1.3,1.7,1.3,1.7l0.3-6.1l-1.6-5.7L257.2,219.1z"
                    />
                    <path
                      className="st2"
                      d="M253.8,211.6c-0.2,0-0.3,0-0.5-0.1l0,0l0.2,2.1c0,0,1.6,2.8,5.2,0.1l0.1-2.6
                    C257.5,212.2,255.5,212.3,253.8,211.6z"
                    />
                    <path
                      className="st15"
                      d="M258,168.6c-5.2,0-5.5,8.3-5.5,8.3l-0.9,17.6l1.1,18.2c0,0,2.9,2.2,6.5-0.5l0.7-18.3l1.6-15.2
                    C261.6,178.6,262,168.6,258,168.6z"
                    />
                    <path
                      className="st17"
                      d="M264.8,163.7c0,2.4,2,4.4,4.4,4.4s4.4-2,4.4-4.4v-7.2h-8.1L264.8,163.7z"
                    />
                    <path
                      className="st17"
                      d="M253.6,215.8l-0.5,5.1l0.9,2.4l1.8,1.3c0,0,1,1.5,1.1,1.7c0.4,0.6,1.3,0.2,1.2-0.5l-0.1-0.7l-0.1-2.3
                    l0.6-2.7L253.6,215.8z"
                    />
                    <path
                      className="st2"
                      d="M265,161.3l-1.9,3c2.3,5.7,7.3,6.5,7.3,6.5l1.1-3.4C266.4,166.3,265,161.3,265,161.3z"
                    />
                    <path
                      className="st2"
                      d="M271.4,167.4c0,0,2.1,1.5,2.7,2.4c0,0,1.1-5.4-0.6-7.1C273.5,162.7,274,165.5,271.4,167.4z"
                    />
                    <path
                      className="st15"
                      d="M264.4,162.3l-2.8,3l1.7,7h1.5l-1.9,0.8c0,0,4.2,3.3,7.6,7.2c2.7,3.1,4.4,4.7,5.4,7.5
                    c0,0,0.5-2.4,0.2-3.4C274.2,178.8,268.1,168.3,264.4,162.3z"
                    />
                    <path
                      className="st15"
                      d="M276.3,168.6l1.3-0.5c-0.6-3.8-4.2-6.1-4.2-6.1v3.2c3.6,9,2.6,22.7,2.6,22.7c2-4.1,2-19.2,2-19.2
                    L276.3,168.6L276.3,168.6z"
                    />
                    <path
                      className="st17"
                      d="M263.8,157.7c0,3.7,6.6,5.7,6.6,5.7c3.5,0,5.4-1.6,5.4-5.4v-9.4l-12.7,2.3L263.8,157.7z"
                    />
                    <path
                      className="st20"
                      d="M274.2,144.8c-5-3.2-9.4-1.3-12.4,2.5c-1.5,1.8-0.5,5.6,0,7.3c1,3.9,3.3,6.1,3.3,6.1l0.4-6.3l0.7,1.2
                    l1.1-0.8c0,0-0.5-2.7-0.4-3.1c0.1-1.5,9-1.7,9-1.7S279.7,148.3,274.2,144.8z"
                    />
                    <path
                      className="st17"
                      d="M263.3,154.8c-0.2,1.6,0.9,3.4,2.2,2.8v-3.1C265.4,154.5,263.5,152.6,263.3,154.8z"
                    />
                    <polygon
                      className="st21"
                      points="272.1,167.9 271.2,168.2 270.5,170.3 272,172 273.8,171.7 274.1,169.8 273.2,168.8 							"
                    />
                    <path
                      className="st21"
                      d="M273.8,171.7L272,172l0.6,4.6l3.5,7.6c0,0,0.1-3.6-0.3-7.1C275.6,175.9,273.8,171.7,273.8,171.7z"
                    />
                    <path
                      className="st14"
                      d="M298.7,208.9c0,0-0.7-1.5-1-2.1c-0.2-0.6-0.5-1.8-0.5-1.8s-0.8,0-1,0.8c-0.2,0.8,0.5,2.6-0.6,2.7
                    c-0.5,0-2.7-1.4-3.8-2c-0.5-0.2-0.4-0.8-0.4-0.8c-2.2,0.2,0.1,5.4,0.5,6.4c0.3,0.9,4,2.9,4,2.9l1.2,1.9l3.6-3.1l-1.8-1.9
                    L298.7,208.9z"
                    />
                    <path
                      className="st21"
                      d="M338,227l-2.3,7.7c0,0,7.3,1.6,7.6-1.9c1.6-14.5-0.6-28.9-0.6-28.9L338,227z"
                    />
                    <path
                      className="st16"
                      d="M338,227l-2.3,7.7c0,0,2.5,0.6,4.7,0.3c0-0.5,0.1-1,0.4-1.6c3-4.9,2.6-9.9,2.9-15.4
                    c0-0.5,0.1-1,0.1-1.5c-0.3-7.3-1-12.5-1-12.5L338,227z"
                    />
                    <path
                      className="st15"
                      d="M332.8,297.4c0,0-2.9,0-5.8,0c-2,0-3.5,3-1.7,3.7l10.6,3.2c0,0,3.7,1.1,4.8-1.5l-0.3-3.9L332.8,297.4z"
                    />
                    <path
                      className="st15"
                      d="M338.6,236.2c-2.4,1.4-20.5,4.3-22.1,2.4l3,65.2c0,1,7.6,2.5,7.6,2.5l2.6-43.4l2.7,36
                    c0.1,1.4-0.1,1.5,1.3,1.7c5.5,1.1,6.8-1.3,6.8-1.3s0.5-41.2,0-52.7C340.4,243.7,338.6,236.2,338.6,236.2z"
                    />
                    <path
                      className="st21"
                      d="M342.8,204c-1.5-5-7.8-5-7.8-5s-3.9,0.5-8.6,1.9c-4.6,1.4-8.5,4.6-8.5,4.6l-2.4,15.1l1.3,21.6
                    c0,0,0.7,2.1,5.7,2.6c3.6,0.3,17-4.9,17-4.9s-0.4-3.1-0.2-6.5C339.4,230,343.9,207.7,342.8,204z"
                    />
                    <path
                      className="st16"
                      d="M342.8,204c-0.5-1.5-1.4-2.6-2.4-3.3c-0.5,0.3-1.1,0.5-1.8,0.4c-5-0.4-10.4,0.8-14.6,3.8
                    c-4.4,3.2-5.4,8.8-5.8,13.9c-0.4,4.8-0.4,9.6-0.7,14.3c-0.2,3.2-0.4,6.6-0.2,9.9c0.7,0.6,2.1,1.5,5.1,1.8
                    c3.6,0.3,17-4.9,17-4.9s-0.4-3.1-0.2-6.5C339.4,230,343.9,207.7,342.8,204z"
                    />
                    <path
                      className="st21"
                      d="M317.9,205.6c-4,3.3-9.3,13.3-9.3,13.3l-9.5-7.6c0,0-3.7-0.2-3.2,4.9c0,0,9.7,13.1,12.6,13.1
                    s11.8-9.5,15.5-14.3C326.7,211.2,323.2,201.3,317.9,205.6z"
                    />
                    <path
                      className="st16"
                      d="M315.5,221.1c-2.6,2.5-6.8,5.8-10.7,5.6c1.5,1.4,2.8,2.4,3.5,2.4c2.9,0,11.8-9.5,15.5-14.3
                    c1.1-1.5,1.2-4.1,0.5-6.3C323.2,213.2,318.6,218.1,315.5,221.1z"
                    />
                    <path
                      className="st2"
                      d="M295.9,216.1c0,0,0.5,0.7,1.4,1.8c0-2.3,0.7-4.5,2.8-6l-1-0.8C299.1,211.2,295.4,211,295.9,216.1z"
                    />
                    <path
                      className="st14"
                      d="M324.4,193.4l0.5,8.1c0,2.4,8.3,1.5,8.3-0.9l-0.6-7.2L324.4,193.4L324.4,193.4L324.4,193.4z"
                    />
                    <path
                      className="st2"
                      d="M333.1,199c-0.7,2.3-5.8,2.8-8.3,1.8l-0.9,1c1.6,2.2,6.9,2.6,9.2,0.1c0.5-0.6,0.6-1.5,0.3-2.2L333.1,199
                    z"
                    />
                    <path
                      className="st14"
                      d="M321.4,194.4c0,3.7,2.6,5.4,6.1,5.4c0,0,6.6-2,6.6-5.7l0.7-6.8l-13.4-2.3L321.4,194.4L321.4,194.4z"
                    />
                    <path
                      className="st15"
                      d="M324.5,180.3c-2.9,1.3-7.5,4.2-3.1,7.3c0,0,1.8,0.4,1.8,3.3c0,2.9,3,5.7,5.3,7.5
                    c1.6,1.3,4.4-0.8,4.4-0.8s4.3-4.9,2.2-13.3C333.8,179.2,326.6,179.4,324.5,180.3z"
                    />
                    <path
                      className="st14"
                      d="M322.8,191.1v3.1c1.3,0.6,2.4-1.2,2.2-2.8C324.7,189.2,322.8,191.1,322.8,191.1z"
                    />
                    <path
                      className="st17"
                      d="M291.4,205.8c0,0,0.2,1.4,2.3,2.2c0.2,0.1,0.4,0.3,0.4,0.5l-0.1,1.3c0,0-0.1,1.5,1.4,1.4l0.4-2.2
                    c0-0.1,0.3-1.6,0.1-1.8C294.8,206.2,291.4,205.8,291.4,205.8z"
                    />
                  </g>
                </g>
                <path
                  className="st16"
                  d="M333.8,264.3c0.1-1.6,1.8-7.9,2.5-9.7c-2.5,1.1-3,1.4-5.5,1.9c0,0.9-0.9,5.5-1,6.4l0.7,15.5l0.8,20.1
                c0.1,1.2,1.4,1.9,2.6,2.1c1.7,0.3,2.9,0,3.7-0.4C336.3,288.2,333.1,277.1,333.8,264.3z"
                />
              </g>
            </g>
          </g>
          <g id="Dymek_x5F_dol_1_" ref={speechBalloon}>
            <path
              className="st2"
              d="M399.6,204.5l-29.4-17c-3.7-2.1-6.6-0.7-6.6,3.2v16.7c0,3.9,2.9,8.8,6.6,10.9l30.1,17.4l6,11.9l0.1-32.2
            C406.2,211.4,403.2,206.5,399.6,204.5z"
            />
          </g>
          <g id="Dymek_x5F_kropki_1_" ref={speechBalloonDots}>
            <g>
              <path
                className="st22"
                d="M374.3,168.8c-2.4-1.4-4.4-0.3-4.4,2.5c0,2.8,1.9,6.1,4.3,7.5c2.4,1.4,4.4,0.3,4.4-2.5
              C378.7,173.6,376.7,170.2,374.3,168.8z"
              />
            </g>
            <g>
              <path
                className="st23"
                d="M389.2,177.5c-2.4-1.4-4.4-0.3-4.4,2.5c0,2.8,1.9,6.1,4.3,7.5c2.4,1.4,4.4,0.3,4.4-2.5
              C393.6,182.2,391.6,178.8,389.2,177.5z"
              />
            </g>
            <g>
              <path
                className="st2"
                d="M404.2,186.1c-2.4-1.4-4.4-0.3-4.4,2.5s1.9,6.1,4.3,7.5c2.4,1.4,4.4,0.3,4.4-2.5
              C408.5,190.9,406.6,187.5,404.2,186.1z"
              />
            </g>
          </g>
          <g id="Ksztalt_x5F_3_1_" className="st1">
            <polygon
              className="st2"
              points="466.2,184.1 466.5,294.2 562.4,238.9 562.1,128.8 			"
            />
          </g>
          <g id="Ksztalt_x5F_2_1_" className="st1">
            <polygon
              className="st2"
              points="424.6,289.7 424.9,368.8 493.7,329.1 493.5,250 			"
            />
          </g>
          <g id="Ksztalt_x5F_1_1_" ref={Shape}>
            <g>
              <g className="st24">
                <polygon
                  className="st25"
                  points="477,161 447,182.5 427.2,225.7 441.5,299 472.9,311.2 503.5,245.1 494,217.3 507.5,188.8 					
                "
                />
                <path
                  className="st2"
                  d="M473.4,307.3c-1.7-0.3-3.3,0.9-3.5,2.6c-0.2,1.7,0.9,3.3,2.6,3.5c1.7,0.2,3.3-0.9,3.5-2.6
                C476.2,309.1,475,307.5,473.4,307.3z"
                />
                <path
                  className="st2"
                  d="M441.9,294.4c-1.7-0.3-3.3,0.9-3.5,2.6c-0.3,1.7,0.9,3.3,2.6,3.5c1.7,0.2,3.3-0.9,3.5-2.6
                C444.8,296.2,443.6,294.7,441.9,294.4z"
                />
                <g>
                  <path
                    className="st2"
                    d="M464.7,233c-1.7,0-3.1,1.4-3.1,3.1s1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1S466.4,233,464.7,233z"
                  />
                  <path
                    className="st2"
                    d="M434,260.4c-1.7-0.3-3.3,0.9-3.5,2.6c-0.3,1.7,0.9,3.3,2.6,3.5c1.7,0.2,3.3-0.9,3.5-2.6
                  C436.8,262.2,435.6,260.7,434,260.4z"
                  />
                  <path
                    className="st2"
                    d="M488.5,272.6c-1.7-0.3-3.3,0.9-3.5,2.6c-0.3,1.7,0.9,3.3,2.6,3.5c1.7,0.2,3.3-0.9,3.5-2.6
                  C491.3,274.4,490.1,272.9,488.5,272.6z"
                  />
                  <g>
                    <polygon
                      className="st2"
                      points="433,262.9 434,264 464.5,237.3 487.3,276 488.7,275.3 464.9,234.9 							"
                    />
                  </g>
                  <g>
                    <polygon
                      className="st2"
                      points="477.3,161.6 476.4,160.4 445.7,182.5 492.7,217.3 464.3,235.5 465.1,236.7 495.3,217.4 
                    448.3,182.5 							"
                    />
                  </g>
                  <g>
                    <path
                      className="st2"
                      d="M447.1,180.4l-20.7,45.2l6.4,37.9L441,298l33,13.5l-8.4-75.5L447.1,180.4z M442.2,296.9l-7.2-30.6
                    l35.4,42.1L442.2,296.9z M434.2,263.1l-6.3-37.2l18.9-41.3l17.2,51.7l7.9,71.7L434.2,263.1z"
                    />
                  </g>
                  <path
                    className="st2"
                    d="M494.5,214.3c-1.7-0.3-3.3,0.9-3.5,2.6c-0.3,1.7,0.9,3.3,2.6,3.5c1.7,0.2,3.3-0.9,3.5-2.6
                  C497.3,216.1,496.2,214.6,494.5,214.3z"
                  />
                  <path
                    className="st2"
                    d="M427.6,222.4c-1.7-0.3-3.3,0.9-3.5,2.6c-0.3,1.7,0.9,3.3,2.6,3.5c1.7,0.2,3.3-0.9,3.5-2.6
                  C430.5,224.2,429.3,222.7,427.6,222.4z"
                  />
                  <path
                    className="st2"
                    d="M447.4,179.4c-1.7-0.3-3.3,0.9-3.5,2.6s0.9,3.3,2.6,3.5c1.7,0.2,3.3-0.9,3.5-2.6
                  C450.2,181.2,449.1,179.7,447.4,179.4z"
                  />
                  <path
                    className="st2"
                    d="M477.4,158c-1.7-0.3-3.3,0.9-3.5,2.6c-0.2,1.7,0.9,3.3,2.6,3.5c1.7,0.2,3.3-0.9,3.5-2.6
                  C480.3,159.8,479.1,158.2,477.4,158z"
                  />
                  <g>
                    <polygon
                      className="st2"
                      points="447.1,181.7 446.9,183.2 507.5,189.4 493.2,217.3 502.7,245.1 488.3,275.3 472.2,310 
                    473.6,310.6 489.6,276 504.2,245.4 504.3,245.2 494.8,217.4 509.8,188.1 							"
                    />
                  </g>
                  <g>
                    <rect
                      x="492.1"
                      y="153.8"
                      transform="matrix(0.6593 -0.7519 0.7519 0.6593 36.453 430.1032)"
                      className="st2"
                      width="1.5"
                      height="42"
                    />
                  </g>
                  <path
                    className="st2"
                    d="M500.7,243.2c-1.1,1.3-0.9,3.3,0.4,4.3c1.3,1,3.3,0.9,4.3-0.4c1-1.3,0.9-3.3-0.4-4.3
                  C503.7,241.8,501.7,241.9,500.7,243.2z"
                  />
                  <path
                    className="st2"
                    d="M506.3,186.8c-1.1,1.3-0.9,3.3,0.4,4.3c1.3,1,3.3,0.9,4.3-0.4c1-1.3,0.9-3.3-0.4-4.3
                  C509.3,185.4,507.3,185.5,506.3,186.8z"
                  />
                </g>
                <g>
                  <rect
                    x="445.2"
                    y="211.4"
                    transform="matrix(0.2726 -0.9621 0.9621 0.2726 102.2216 597.0168)"
                    className="st2"
                    width="1.5"
                    height="39"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Szescian_x5F_dolny_1_" ref={Box}>
            <g>
              <g className="st4">
                <g>
                  <polygon
                    className="st15"
                    points="91.9,299.3 126.5,319.5 161.4,299.3 126.7,279.2 						"
                  />
                </g>
                <g>
                  <polygon
                    className="st26"
                    points="91.9,299.3 92,332.7 126.6,352.7 126.5,319.5 						"
                  />
                </g>
                <g>
                  <polygon
                    className="st27"
                    points="126.5,319.5 126.6,352.7 161.5,332.7 161.4,299.3 						"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Szescian_x5F_lewitujacy_1_" ref={Cube}>
            <g>
              <g>
                <path
                  className="st2"
                  d="M107.2,241.4l-0.2,22.5l19.4,11.4l19.6-11.1l0.2-22.5l-19.4-11.4L107.2,241.4z M144.6,263.5l-18.2,10.3
                l-18-10.6l0.2-20.9l18.2-10.3l18,10.6L144.6,263.5z"
                />
              </g>
              <g>
                <polygon
                  className="st2"
                  points="107.4,262.9 108.1,264.2 126.5,253.7 145,264.5 145.7,263.3 126.6,252.1 					"
                />
              </g>
              <g>
                <rect
                  x="115.8"
                  y="241.4"
                  transform="matrix(1.280046e-02 -0.9999 0.9999 1.280046e-02 -117.0627 365.6276)"
                  className="st2"
                  width="21.7"
                  height="1.4"
                />
              </g>
            </g>
            <polygon
              className="st25"
              points="107.9,241.9 107.7,263.6 127.1,300.5 145,265.2 145.5,242.2 126.8,231.2 			"
            />
          </g>
          <g id="Ludziki_x5F_po_x5F_lewej_1_" ref={peopleLeft}>
            <g>
              <g>
                <path
                  className="st28"
                  d="M82.9,355.2c-4.6-2.5-11.8-4.8-17-4.5c-6.8,0.2-13.8,5-6.9,8.1c9.7,4.3,11.8,2.2,17.8,2
                C81.4,360.6,90.4,359.4,82.9,355.2z"
                />
                <g>
                  <g>
                    <g>
                      <path
                        className="st15"
                        d="M83,356.2c-3.3,0-7.8-0.7-7.8-0.7l-6.5,1.3l-0.3,4.3c1.2,2.9,4.8,1.9,4.8,1.9l11.7-2.6
                      C86.8,358.7,85.2,356.2,83,356.2z"
                      />
                      <path
                        className="st15"
                        d="M69,349.1c-3.1,0-7.2-0.4-7.2-0.4l-5.6,1.7l-0.3,4.1c1.1,2.7,5.1,1.6,5.1,1.6l9.7-3.1
                      C72.7,352.4,71.1,349.1,69,349.1z"
                      />
                      <path
                        className="st11"
                        d="M56,286c0,0-1.9,8.8-2.2,11.7c-0.3,3.6,0.2,3.2,0.6,9.2c0.6,7.2,1.7,18.9,1.7,18.9l-0.2,25
                      c0,0,0.6,1.5,4.2,1c1.4-0.2,2.8-1,2.9-2.3l2.4-23.7l0.4-17.1l4.8,15.7l-2,33.4c0,0,6.1,2.6,7-0.7l3.8-32.4l-2.3-34.3
                      C74.3,295.3,56.7,286.4,56,286z"
                      />
                      <path
                        className="st9"
                        d="M58.9,351.8c0.4,0,0.9,0,1.4-0.1c1.4-0.2,2.8-1,2.9-2.3l2.4-23.7l0.4-17.1c0,0-0.3,0.4-0.8,1
                      C62.7,315.1,60.9,344.3,58.9,351.8z"
                      />
                      <path
                        className="st9"
                        d="M56,286c0,0-0.2,0.9-0.5,2.3c1.8-0.5,3.9-0.4,6,0.9c12.1,7,16.6,19.8,15.9,33.2
                      c-0.6,12.3-3.3,24.2-4.1,36.2c1.1-0.1,2.1-0.5,2.4-1.7l3.8-32.4l-2.3-34.3C74.3,295.3,56.7,286.4,56,286z"
                      />
                    </g>
                    <path
                      className="st29"
                      d="M52.5,253.9c0,0-1.6,7.9-1.6,22.8c0,3.5,6.8,8.1,6.8,8.1l-0.6-24.3L52.5,253.9z"
                    />
                    <path
                      className="st9"
                      d="M54.9,257.3c-3,7.3-3.6,16.4-1.1,24.2c1.9,1.9,4,3.3,4,3.3l-0.6-24.3L54.9,257.3z"
                    />
                    <path
                      className="st29"
                      d="M78.1,252.6c-1.8-0.8-4.6-2.8-7-4c-5.5-3-12.4-0.1-12.4-0.1c-10.7,4.2-4.5,9.6-4.8,10.8
                    c-0.2,1.2,2.5,18.4,2.5,18.4L54,293.5c0,0,2.4,1.1,11.1,3c8.7,1.9,13-0.5,13-0.5s-1.1-8.4-0.7-14.7
                    c0.6-9.1,1.4-18.1,1.4-18.1C81.6,259.2,84.2,255.4,78.1,252.6z"
                    />
                    <path
                      className="st9"
                      d="M80.1,253.8c-1.3,2.4-3.8,3.8-6.5,4c0.5,1.9,0.7,3.8,0.6,5.7c0.1,0.2,0.1,0.5,0.2,0.7
                    c1.4,8.5,0.4,17-0.4,25.5c-0.2,2.5-0.5,5-0.9,7.5c3.3-0.3,5-1.2,5-1.2s-1.1-8.4-0.7-14.7c0.6-9.1,1.4-18.1,1.4-18.1
                    C81.2,259.7,83.5,256.4,80.1,253.8z"
                    />
                    <path
                      className="st30"
                      d="M119.3,241.7l-8.4,5.1l-4.6,3.2l0.6,4.8l3.8-1.9c1,0.5,4.1-1.4,4.1-1.4c0.4,0.7,0.9,0.8,0.9,0.8
                    l1.6-2.6c0.1-2.1-1.2-3.9-1.2-3.9l3.4-2.4C120.1,242.1,119.3,241.7,119.3,241.7z"
                    />
                    <g>
                      <path
                        className="st30"
                        d="M62.4,247.7c0,2.4,8.3,3.2,8.3,0.8l0.4-8.1l-8.1,0.1L62.4,247.7z"
                      />
                      <path
                        className="st2"
                        d="M62.4,246.2l-0.4,0.7c-0.3,0.7-0.2,1.6,0.3,2.2c2.4,2.5,7.8,2.3,9.3,0.1l-0.9-1.9
                      C68.2,248.2,63.2,248.5,62.4,246.2z"
                      />
                      <path
                        className="st30"
                        d="M60.5,234.4l0.8,6.8c0.1,3.7,6.6,5.6,6.6,5.6c3.6-0.1,6.1-1.7,6.1-5.5l-0.1-9.4L60.5,234.4z"
                      />
                      <path
                        className="st31"
                        d="M72.6,227.2c-1.4-0.8-11.1-0.9-12.4,4.2c-2.1,8.4,2.3,13.3,2.3,13.3s2.8,2.1,4.4,0.8
                      c2.2-1.8,5.2-4.7,5.2-7.6c0-2.9,1.8-3.3,1.8-3.3C75.2,233.7,73.4,227.7,72.6,227.2z"
                      />
                      <path
                        className="st30"
                        d="M70.4,238.5c-0.2,1.6,0.9,3.4,2.2,2.8v-3.1C72.6,238.1,70.7,236.3,70.4,238.5z"
                      />
                    </g>
                    <path
                      className="st29"
                      d="M106.6,249.4l-12.1,6.9c-1.2,0.7-2.7,0.9-4,0.5c-6-1.8-14.9-4.6-16.4-4.6c-7.9,0.4-5.2,8.4,0,10.4
                    c3.5,1.4,14.2,3,18.9,3.4c1.4,0.1,2.8-0.3,3.9-1.2l13.1-11c0,0,0.3,0.6-0.3-1.5C109.1,250.1,106.6,249.4,106.6,249.4z"
                    />
                    <path
                      className="st9"
                      d="M96,262.4c-6.1,2.6-14.2,1.2-20.8,0.6c4.2,1.3,13.6,2.7,17.9,3.1c1.4,0.1,2.8-0.3,3.9-1.2l13.1-11
                    c0,0,0.3,0.6-0.3-1.5C108.1,253.4,99.2,261,96,262.4z"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <path
                className="st32"
                d="M217.9,332c-0.7-0.4-1.7-0.4-2.5-0.6c-0.8-0.2-1.6-0.5-2.4-0.6c-0.4,0-0.7,0.1-1.1,0
              c-0.5-0.1-0.9-0.3-1.4-0.4c-1.1-0.4-2.2-0.7-3.3-1c-0.7-0.2-1.3-0.4-2-0.5c-0.9-0.2-1.6-0.2-0.8,0c-1.1,0-2.2,0-3.4,0.2
              c-1.5,0.2-3.1,0.1-4.7,0c-0.9,0-1.6,0.2-2.5,0.2c-0.7,0-1.6,0.4-2.3,0.7c-0.9,0.5-1.8,1-2.7,1.6c-0.3,0.2-1.1,0.6-1.3,0.8
              c-0.2,0.3-0.1,0.6-0.1,1c0,0.9,0.6,1.7,1.4,2.2c0.9,0.6,2,0.8,3.1,1c2.1,0.5,4.2,0.8,6.4,1c2.8,0.4,5.6,0.5,8.3,0.9
              c2,0.3,4.3,0,6.4,0.1c1.1,0,2.2-0.1,3.2-0.3c0.5-0.1,0.7-0.3,1.2-0.4c0.7-0.3,1.5-0.5,1.9-1.1c0.4-0.5,0.5-1.3,0.4-1.8
              C219.3,334.1,218.8,332.5,217.9,332z"
              />
              <path
                className="st15"
                d="M190.6,326.9c-1.9,0.7-12.3,4.8-12.3,4.8c-0.9,3.8,6.5,2.6,6.5,2.6l10.7-1.7c1.1-0.2,1.9-1.3,1.6-2.4
              C196.4,326.4,192.3,326.3,190.6,326.9z"
              />
              <path
                className="st15"
                d="M214.7,331.1c-1.7,1.1-10.8,7.6-10.8,7.6c0,3.9,6.9,1,6.9,1l9.7-4.1c1.3-0.5,1.8-2.1,1.1-3.3
              C219.7,329.4,216,330.2,214.7,331.1z"
              />
              <g>
                <path
                  className="st15"
                  d="M159.6,238.2c-0.5-0.6-4.2-2.4-4.7-2.1c-0.5,0.2-2.6,0.4-3.5,0.9c-0.9,0.5-1.1,0.9-1.1,0.9
                s1.8,0.1,2.7,0.5c1.1,0.5,1.2,2.6,0.4,2.7c-0.8,0.1-2.8-0.6-2.9,0.6c0,0.1,2.4,0.6,3.7,1.2c1.2,0.5,4.3-0.7,4.3-0.7l3.7-0.9
                c0,0,1.7-2.8,1.1-3.3C162.5,237.3,159.9,238.6,159.6,238.2z"
                />
                <path
                  className="st15"
                  d="M148.8,238.1c-0.8,0.4-1.5,1-0.6,1.1s2.2-0.5,3.6-0.4c1.4,0.1,2.9,0.3,2.9,0.3l-0.2-2.8
                C154.5,236.3,149.6,237.6,148.8,238.1z"
                />
              </g>
              <g>
                <path
                  className="st29"
                  d="M196.6,220.4c-0.4,0.1-0.7,0.3-1,0.5l0,0c0,0-16.3,12.6-16.3,12.7c0,0-0.5,2.6,0.4,3.9
                c1,1.5,2.8,2.3,3,2.1L201,229l0,0c1.9-1.2,2.8-3.7,2-5.9C201.9,220.7,199.1,219.5,196.6,220.4z"
                />
                <path
                  className="st33"
                  d="M196.6,220.4c-0.4,0.1-0.7,0.3-1,0.5l0,0c0,0-1.7,1.3-4,3.1c-0.4,0.6-0.9,1.2-1.3,1.7
                c-2.8,4.6-5.5,9.9-10.4,12c1,1.3,2.6,2.1,2.8,1.9L201,229l0,0c1.9-1.2,2.8-3.7,2-5.9C201.9,220.7,199.1,219.5,196.6,220.4z"
                />
                <path
                  className="st29"
                  d="M177.9,234.7c-0.8-0.5-18.1,2.8-18.1,2.8s0,0.1-0.1,0.1c-1.4,1.5-0.5,4.1,1.6,4.4c10.3,1.4,19-0.9,19-0.9
                C182,235.5,178.7,235.3,177.9,234.7z"
                />
                <path
                  className="st33"
                  d="M159.2,239c-0.2,1.4,0.7,2.8,2.3,3c5.5,0.8,10.5,0.4,14.1,0c-0.6,0-1.2,0-1.7-0.1
                C168.9,241.7,163.9,240.7,159.2,239z"
                />
              </g>
              <g>
                <path
                  className="st29"
                  d="M202.5,255.1c-3.8-1-7.7,1.3-8.5,5.1l-5.7,30.3c0.7,6.9,8.2,5.6,8.9,2.5l10.6-29.4
                C208.7,259.9,206.3,256.1,202.5,255.1z"
                />
                <path
                  className="st33"
                  d="M202.5,255.1c-1.1-0.3-2.2-0.3-3.3-0.1c0.3,2.8,0.5,5.7,0.7,8.6c0.9-0.5,2.3,0,2.1,1.3
                c-0.7,8.2-3,16.6-7.8,23.3c-1.3,2.1-2.8,3.9-4.8,5.5c2.3,3.2,7.2,1.9,7.7-0.6l10.6-29.4C208.7,259.9,206.3,256.1,202.5,255.1z"
                />
                <path
                  className="st29"
                  d="M193.2,295c-3.3-0.1-6.1,2.5-6,5.8l4.1,26.2c0,2.8,4.8,3.1,4.8,0.4l1.4-25.9
                C197.4,298.2,196.5,295.1,193.2,295z"
                />
                <path
                  className="st33"
                  d="M194.9,319.3c-0.4,3.3-0.8,6.7-2,9.8c1.4,0.5,3.2,0,3.2-1.7l1.4-25.9c0-1.5-0.2-3-0.8-4.2
                C196,304.6,195.7,311.9,194.9,319.3z"
                />
              </g>
              <path
                className="st15"
                d="M213.4,255.2c-0.9-0.7-1.5-1.8-1.6-2.9l-0.2-4.2l-13.7-1.2l-0.1,8.8c0,0-1.6,2.8-1.3,4.5
              c0.8,4.8,4.6,8.1,5.8,8.1c8.8,0.4,12.9-1.9,12.9-1.9s2.3-2,1.4-6.3C216.1,257.7,214.6,256.1,213.4,255.2z"
              />
              <path
                className="st29"
                d="M209.4,258.8c-4,0.1-7,3.3-6.8,7.2l2.8,30.8c2.6,6.4,9.4,3.2,9.2,0l2.3-31.2
              C216.6,261.8,213.3,258.8,209.4,258.8z"
              />
              <path
                className="st33"
                d="M212.8,286.5c-0.6,4.3-1.2,10.5-5.1,13.1c3,2,7.1-0.5,7-2.9l2.3-31.2c-0.1-1.4-0.6-2.7-1.4-3.8
              C215.3,270.2,214,278.2,212.8,286.5z"
              />
              <path
                className="st29"
                d="M216.3,303.9c-0.6-3.2-2.1-6.1-5.3-5.6c-3.3,0.5-5.5,3.6-4.9,6.8l8.5,25.9c0.5,2.7,5.7,1.2,5.1-1.4
              L216.3,303.9z"
              />
              <path
                className="st33"
                d="M216.3,303.9c-0.6-2.9-1.9-5.5-4.6-5.6c0,0.1,0,0.1-0.1,0.2c0.1,0.4,0.2,0.7,0.1,1.1c0.5,0.1,0.9,0.4,1,1
              c2.1,9.8,4.1,21.3,3.3,31.8c1.7,0.3,4.1-0.9,3.7-2.8L216.3,303.9z"
              />
              <path
                className="st15"
                d="M205.4,296.7c-1.9,4.1,0,6.7,0.3,7c0.5,0.6,1.4,0.8,2.1,0.5c0.9-0.4,2.2-1,2.8-1.6
              c2.5-2.5,3.1-3.4,3.6-3.4c0,0-0.1-0.7-0.1-0.6C211.7,298.9,208,297.4,205.4,296.7z"
              />
              <path
                className="st29"
                d="M214.8,220.5c-1.5-1-3.2-1.8-5-2.6c-3.8-1.6-5.9-0.3-12.4,1.5c-2.4,0.7-4.2,2.6-4.3,6.6
              c0,4.8,1.2,15.1,1.1,17.2c-0.1,2.4,1.2,4.8,3.1,5.8c4,2.1,8.2,3.2,10.8,2.5c2.7-0.7,5.8-3.1,5.8-3.1l2.5-13.9l1.3-5.6
              C218.6,225.7,217.4,222.2,214.8,220.5z"
              />
              <path
                className="st33"
                d="M214.8,220.5C214.8,220.5,214.7,220.5,214.8,220.5c-4.4,2-3.2,7.7-4.5,11.7c-1,3.2-2.7,6.3-4.7,8.9
              c-2.6,3.3-5.9,5.6-9.6,6.7c0.4,0.5,0.9,0.9,1.5,1.2c4,2.1,8.2,3.2,10.8,2.5c2.7-0.7,5.8-3.1,5.8-3.1l2.5-13.9l1.3-5.6
              C218.6,225.7,217.4,222.2,214.8,220.5z"
              />
              <g>
                <path
                  className="st15"
                  d="M230.7,263.2c-0.6-0.6-3.3,1.3-3.3,1.3l-0.6,3.8c0,0-1,3.2-0.4,4.3c0.7,1.3,1.4,3.6,1.4,3.6
                c1.2-0.2,0.3-2.1,0.4-3s2.2-0.9,2.8,0.2c0.5,0.9,0.7,2.7,0.7,2.7s0.4-0.2,0.8-1.1c0.4-0.9,0.4-3,0.6-3.5c0.2-0.5-1.9-4-2.5-4.5
                C230.4,266.7,231.4,264.1,230.7,263.2z"
                />
                <path
                  className="st15"
                  d="M230.8,274.8c0.2,1.3-0.3,2.7-0.1,3.6c0.2,0.9,0.7,0.2,1.1-0.7c0.4-0.9,1.4-5.9,1.4-5.9h-2.8
                C230.3,271.9,230.5,273.5,230.8,274.8z"
                />
              </g>
              <g>
                <path
                  className="st29"
                  d="M219.8,223.8L219.8,223.8c-0.8-2.1-3-3.4-5.4-2.9c-2.6,0.5-4.3,3-3.8,5.6c0.1,0.4,0.2,0.7,0.4,1.1l0,0
                l9.8,18.8l0,0v0.1l0,0c0.7,1.5,2,0.8,3.7,0.4c2-0.4,2.6-3.1,2.4-3.5L219.8,223.8z"
                />
                <path
                  className="st33"
                  d="M223.2,233.2c0,0.2,0.1,0.5,0.1,0.7c0.4,4.3-1.3,7.9-2.9,11.7l0.4,0.7l0,0v0.1l0,0c0.7,1.5,2,0.8,3.7,0.4
                c2-0.4,2.6-3.1,2.4-3.5L223.2,233.2z"
                />
                <path
                  className="st29"
                  d="M223.8,245.5c-2,0.8-1.9,2.6-2.1,3.2c-0.2,0.4,2.2,5.1,3.6,9.2c1.5,4.4,2,8.2,2,8.2
                c2.8,2,4.2-0.5,4.2-0.5c0.2-11.1-3.2-19.8-3.2-19.8C226.3,244.9,224.6,245.1,223.8,245.5z"
                />
                <path
                  className="st33"
                  d="M227.7,245.6c0,0.1,0.1,0.1,0.1,0.2c1.3,6.9,2.5,14.1,1,21c1.8,0.3,2.6-1.2,2.6-1.2
                c0.2-11.1-3.2-19.8-3.2-19.8C228,245.7,227.8,245.6,227.7,245.6z"
                />
              </g>
              <path
                className="st15"
                d="M224.1,243.8c-1.7,0.3-2.8,2-2.5,3.7s2,2.8,3.7,2.5s2.8-2,2.5-3.7C227.5,244.6,225.8,243.5,224.1,243.8z"
              />
              <path
                className="st15"
                d="M181.3,234.6c-1.5-0.8-3.4-0.2-4.2,1.4c-0.8,1.5-0.2,3.4,1.4,4.2c1.5,0.8,3.4,0.2,4.2-1.4
              C183.4,237.3,182.9,235.4,181.3,234.6z"
              />
              <path
                className="st15"
                d="M202.7,213l0.6,6.8c0.1,1.6,1.5,2.9,3.1,2.9c1.9,0,3.3-1.6,3.1-3.5l-0.6-5.3l3.5-4.1
              C206.2,207.1,202.7,213,202.7,213z"
              />
              <path
                className="st29"
                d="M206,196.6c-4.5,0-8.3,3.7-8.3,8.3c-0.5,4.9-0.3,8.1,0,9.9c0.2,1.3,1.3,2.3,2.6,2.3c2.1,0,5.3-1.5,5.3-1.5
              l1.7-2.6c3.9-0.6,6.9-4,6.9-8.1C214.2,200.3,210.5,196.6,206,196.6z"
              />
              <path
                className="st33"
                d="M213.3,205.5c-1.7,2.5-4.6,4.1-6.8,6.1c-2.2,2-4.3,3.8-7.1,5c-0.2,0.1-0.3,0.1-0.5,0.1
              c0.4,0.3,0.9,0.4,1.4,0.4c2.1,0,5.3-1.5,5.3-1.5l1.7-2.6c3.9-0.6,6.9-4,6.9-8.1c0-0.3,0-0.6-0.1-0.9
              C213.9,204.4,213.7,204.9,213.3,205.5z"
              />
              <path
                className="st15"
                d="M188.4,291.7c-3.8,4.1-1.1,7.8-1.1,7.8s2,1.1,3.7-0.4c2.7-2.3,4.9-2.6,5.3-2.4c0.3-0.4,0-1.4-0.2-2.1
              C193.8,294.3,190.9,292.9,188.4,291.7z"
              />
              <g>
                <path
                  className="st15"
                  d="M205.2,204.9l-7.7-2.1c-0.4-0.1-1.1,0-1.4,0.8l-0.4,2c0.1,0.4-0.3,1.9,1,2.2l2.1,0.7
                c0.2,0.1,0.3,0.1,0.4,0c0,0,0.3-0.3,0.5-0.2c0.2,0.1,0.6,0.5,0.6,0.6c0.1,0.2,0.3,0.3,0.5,0.4l1.7,0.6c1.6,0.3,2.6-1,2.9-1.4
                l0.5-2.7C205.8,205.4,205.5,205,205.2,204.9z"
                />
              </g>
            </g>
          </g>
          <g id="Ludzik_x5F_prawo_1_" ref={peopleRight}>
            <g>
              <g>
                <path
                  className="st32"
                  d="M401.6,379.5c-4-0.4-8.1,1.3-11.9,2.6c-1.8,0.6-6,1.4-7.1,2.9c-4.6,6.1,15.6,4.2,17.5,4
                c2.7-0.3,15.9-0.6,15.4-4.5C415.1,380.4,403.9,379.4,401.6,379.5z"
                />
                <g>
                  <g>
                    <g>
                      <g>
                        <path
                          className="st11"
                          d="M408.3,278c-1.4-2.4-8.2-4-8.2-4c0.1,0.2,6.6,27.9,6.7,28.9c2.5,7.4,6.3,3.4,7.8,1.5l-2.2-6.1
                        C412.3,298.4,410.9,282.7,408.3,278z"
                        />
                        <path
                          className="st11"
                          d="M423.5,296.6l-11.2,2.7c0.2,2.5-3.5,4.2-3.3,6.9l2.8,0.7l13-6.1C424.8,300.8,427.3,297.9,423.5,296.6
                        z"
                        />
                        <path
                          className="st2"
                          d="M423.5,296.6l-2.5,0.6c-0.8,2.3,0,3.8,2.1,4.4l1.6-0.8C424.8,300.8,427.3,297.9,423.5,296.6z"
                        />
                      </g>
                      <path
                        className="st15"
                        d="M411.8,380.2l-5.2-5.2H401l-0.6,3.9c0,0.9,0.5,1.6,1.2,2.1l2.3,0.7c1.5,0.5,2.9,1.1,4.1,2
                      c1.5,1,4,1.5,5.4,1.1c1.2-0.4,1.8-0.6,1.4-1.9C414.5,382.1,413.6,381.2,411.8,380.2z"
                      />
                      <path
                        className="st15"
                        d="M395.2,384.4l-4.1-5.5h-5.4l-0.4,3.8c0,0.8,0.5,1.6,1.2,2l5.4,4.5c1.1,0.9,2.6,1.4,4,1.2
                      c1.1-0.1,2.3-0.7,2.7-2.1v-0.2C398.3,386.8,396.2,385.4,395.2,384.4z"
                      />
                      <path
                        className="st15"
                        d="M406.8,309.4l-8.1,2.8l-1,12.4l1.9,22.9l0.9,28.5c0,0,3.7,2.4,7.3-0.3l0.8-30.5c0,0-0.3-16.6-0.8-22
                      C406.9,314.2,406.8,309.4,406.8,309.4z"
                      />
                      <path
                        className="st32"
                        d="M407.2,336.5c-0.2,5.3,0,10.9-0.5,16.3c-0.2,8.2-0.5,15.7-1.2,23.9c0.7-0.2,1.5-0.5,2.2-1.1l0.8-30.5
                      c0,0-0.3-16.3-0.7-21.9C407.6,327.7,407.3,332.1,407.2,336.5z"
                      />
                      <path
                        className="st15"
                        d="M385,327.1l1,22.6l-0.7,30.1c0,0,4,2.9,6.7-0.1l3.9-29.9l2.8-24.8l3.3-11.6l-16.4-0.8
                      C385.5,312.5,384.5,315.3,385,327.1z"
                      />
                      <path
                        className="st32"
                        d="M385,327.1l1,22.6l-0.7,30.1c0,0,0.5,0.4,1.3,0.7c0.1-1.3,0.2-2.5,0.3-3.8c0-9.3,0.3-17.9,0.5-27.3
                      c0.1-4.5,0.2-8.9,0.4-13.3c-1.1-6.2-0.6-12.5,0.3-18.7c0-0.3,0-0.6,0-0.9c0-0.8,0.2-1.4,0.5-2c0.1-0.6,0.2-1.2,0.3-1.8
                      l-3.2-0.1C385.5,312.5,384.5,315.3,385,327.1z"
                      />
                      <path
                        className="st32"
                        d="M396.8,329.6c-1,4.6-2.1,14.5-2.9,19.2c0.1,1.4,0,2.8-0.3,4.2c0,0.3,0,0.6-0.1,0.9
                      c-2.4,9.1-3,17.8-4.3,27.1c0.9-0.1,1.9-0.4,2.8-1.4l3.9-29.9l2.3-19.9l2.1-2.5C400.1,327.3,396.9,329,396.8,329.6z"
                      />
                      <path
                        className="st11"
                        d="M408.2,281.1c0-3.5-1.9-5.1-4.3-5.7c-6.5-1.5-8.7-2.7-12.5-1.3c-4.8,1.8-9.4,5.1-9.4,5.1l2.6,9.4
                      l1.6,14l-1.1,11.9c8.3,6.7,21.9,0.3,21.9,0.3s-0.1-10.4-0.4-14.3c-0.1-1.6,0.3-6,0.3-6C408.4,288.3,408.2,285.3,408.2,281.1
                      z"
                      />
                      <path
                        className="st32"
                        d="M386.5,293.1c-0.7-0.4-0.4-6.4-0.6-7.5c-0.4-2.1-1.3-3.5-2.4-5.2c-0.4-0.6-0.6-1.2-0.6-1.7
                      c-0.5,0.3-0.8,0.6-0.8,0.6l2.6,9.4l1.6,14l-1.1,11.9c0.6,0.5,2.5,1.5,3.2,1.9c0-4,0.3-6.5,0-11.4
                      C388.2,301.9,387.1,298.3,386.5,293.1z"
                      />
                      <g>
                        <path
                          className="st34"
                          d="M378.7,320.8l-1.3,6.4l0.7,2.3l1.6,1.3c0,0,0.9,1.5,1,1.6c0.3,0.6,1.2,0.2,1.1-0.4l-0.1-0.7v-2.1
                        l0.7-2.3l0,0v-0.2l-0.2,2c-0.1,1.7,1.1,1.6,1.1,1.6l0.5-5.6l-1.2-4.3L378.7,320.8z"
                        />
                      </g>
                      <g>
                        <path
                          className="st34"
                          d="M391.4,274.2c0,2.3,1.8,4.1,4.1,4.1c2.3,0,4.1-1.8,4.1-4.1v-6.6h-7.5L391.4,274.2z"
                        />
                        <path
                          className="st32"
                          d="M391.9,268.9c0.1,0.4,0.1,0.7,0.1,1.2c-0.1,3,3,7.3,6.3,5.2c0.9-0.6,1.2-1.6,1.2-3.1l0,0v-4.6H392
                        L391.9,268.9z"
                        />
                        <path
                          className="st34"
                          d="M390.5,268.6c0,3.4,4.1,5,6.1,5.3c2.4,0.3,5-1.5,5-5v-8.7l-11.8,2.1L390.5,268.6z"
                        />
                        <g>
                          <path
                            className="st15"
                            d="M400.1,256.7c-4.7-2.9-8.7-1.2-11.5,2.3c-1.4,1.7-0.4,5.2,0,6.8c0.9,3.7,3,5.7,3,5.7l0.3-5.8
                          l0.7,1.1l1-0.7c0,0-0.4-2.5-0.4-2.9c0.1-1.4,8.4-1.6,8.4-1.6S405.3,259.9,400.1,256.7z"
                          />
                          <path
                            className="st34"
                            d="M390,265.9c-0.2,1.5,0.8,3.1,2,2.6v-2.9C392,265.6,390.3,263.9,390,265.9z"
                          />
                          <path
                            className="st2"
                            d="M391.6,272l-1.8,2.8c2.1,5.3,6.8,6,6.8,6l1-3.1C391.9,276.7,391.6,272,391.6,272z"
                          />
                          <path
                            className="st2"
                            d="M397.6,277.6c0,0,2,1.4,2.5,2.2c0,0,1.1-5-0.5-6.6C399.6,273.3,400,276,397.6,277.6z"
                          />
                        </g>
                      </g>
                      <path
                        className="st11"
                        d="M385.1,278.2c-4.9-0.5-5.7,7.7-5.7,7.7l-2.2,16l1.2,19.1c0,0,0.6,1.1,4.2-0.7l1.2-17.3l4-15.5
                      C387.9,287.6,387.9,278.5,385.1,278.2z"
                      />
                      <path
                        className="st32"
                        d="M385.7,292l-1.6,6.8c-0.8,1.9-1.6,3.6-1.8,4.2c-0.5,5.8-0.7,12.3-1.2,18.1c0.5-0.1,1-0.4,1.6-0.7
                      l1.2-17.3l2.6-9.9l-1.1-5.2L385.7,292z"
                      />
                      <path
                        className="st2"
                        d="M378.3,318.9l0.1,2.3c0,0,2.4,0.9,4.4-0.5l0.1-2C382.8,318.7,382,319.6,378.3,318.9z"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <path
                className="st34"
                d="M427,292.9l-3.8,4c-0.6,0.6-0.7,1.6-0.2,2.3c0.6,1,2.1,1.2,2.9,0.3l0.3-0.3c1.6,0.4,3.6-0.7,3.6-0.7
              c0.2,0.7,0.9,0.3,0.9,0.3l1.9-2.3l-1.5-2.2c0,0,0,0,2.1-0.4s1.3-1.5,1.3-1.5L427,292.9z"
              />
            </g>
            <g>
              <g className="st4">
                <g>
                  <path
                    className="st15"
                    d="M407.7,262.2l-9.6,3.4c-0.3,0.1-0.7,0.5-0.8,0.9l-0.8,4.5c-0.1,0.4,0.2,0.6,0.5,0.4l3.8-1.3
                  c0.2-0.1,0.4-0.2,0.5-0.4c0,0,0.4-0.5,0.6-0.6c0.2-0.1,0.5,0.2,0.5,0.2c0.1,0.1,0.3,0.1,0.4,0l3.7-1.3
                  c0.4-0.1,0.7-0.5,0.8-0.9l0.8-4.5C408.3,262.3,408,262.1,407.7,262.2z"
                  />
                </g>
                <path
                  className="st2"
                  d="M404,259l-9.6,3.4c-0.3,0.1-0.7,0.5-0.8,0.9l-0.1,4.7c0,0.2,0,0.3,0.1,0.4l3,3c-0.1-0.1-0.1-0.2-0.1-0.4
                l0.8-4.5c0.1-0.4,0.4-0.8,0.8-0.9l9.6-3.4c0.2-0.1,0.3,0,0.4,0l-3.7-3.2C404.3,259,404.2,259,404,259z"
                />
                <path
                  className="st15"
                  d="M397.4,264.3c-1.1,0.7-0.9,1.1-1.1,2c-0.3,1.3-0.8,3.9-0.8,4l1.1,1.1c-0.1-0.1-0.1-0.2-0.1-0.4l0.8-4.5
                c0.1-0.4,0.4-0.8,0.8-0.9l9.6-3.4c0.2-0.1,0.3,0,0.4,0l-1.5-1.3C403.5,261.9,400.5,263.3,397.4,264.3z"
                />
              </g>
            </g>
            <polygon
              className="st2"
              points="388.1,264.2 393.7,266.8 394.5,265.2 387.8,262.4 			"
            />
          </g>
          <g id="interface" ref={Interface}>
            <g id="Ekran_1_1_" ref={InterfaceOne}>
              <g className="st36">
                <path
                  className="st37"
                  d="M315.1,342.8c-23,13.4-62.4,14-87,2.1v59.2c24.5,11.9,64,11.2,87-2.1c5.6-3.2,9.8-6.9,12.8-10.9v-59.2
              C325,335.9,320.7,339.6,315.1,342.8z"
                />
              </g>
              <g className="st38">
                <g>
                  <path
                    className="st2"
                    d="M268.6,369v3c1.7,0.2,4.2,0.3,7.1,0.3c8.7,0,21.9-1.5,34.6-8.4v-3.5C293.6,370.3,275.1,369.6,268.6,369z"
                  />
                </g>
                <g>
                  <path
                    className="st2"
                    d="M268.6,376.4v3c1.7,0.2,4.2,0.3,7.1,0.3c8.7,0,21.9-1.5,34.6-8.4v-3.5C293.6,377.7,275.1,377,268.6,376.4z
                "
                  />
                </g>
                <g>
                  <path
                    className="st2"
                    d="M268.6,383.8v3c1.7,0.2,4.2,0.3,7.1,0.3c8.7,0,21.9-1.5,34.6-8.4v-3.5
                C293.6,385.1,275.1,384.4,268.6,383.8z"
                  />
                </g>
                <g>
                  <path
                    className="st2"
                    d="M268.6,391.2v3c1.7,0.2,4.2,0.3,7.1,0.3c8.7,0,21.9-1.5,34.6-8.4v-3.5
                C293.6,392.5,275.1,391.8,268.6,391.2z"
                  />
                </g>
                <g>
                  <path
                    className="st2"
                    d="M268.6,398.6v3c1.7,0.2,4.2,0.3,7.1,0.3c8.7,0,21.9-1.5,34.6-8.4V390C293.6,399.9,275.1,399.2,268.6,398.6
                z"
                  />
                </g>
              </g>
              <g id="Ekran_2_1_" ref={InterfaceTwo}>
                <path
                  className="st35"
                  d="M190.5,407.9c29.4,24.4,67.5,26,67.5,26v-58.9c-40.4-3.6-67.5-28-67.5-28V407.9z"
                />
                <path
                  className="st25"
                  d="M209,372.3L209,372.3L209,372.3c-7.8-4.5-14.2-0.9-14.2,8.1c0,8.9,6.3,19.9,14.1,24.4
            c7.8,4.5,14.2,0.9,14.2-8.1S216.8,376.8,209,372.3z M205,400.5c-3.1-3-5.8-7-7.4-11.2l4,2.3l1.1,0.7l5.6,3.2v7.5
            c-2.4-1.8-4.5-5.9-5.6-10.8l-1.1-0.7C202.4,395,203.6,398.1,205,400.5c1,1.7,2.2,3,3.4,3.9C207.2,403.5,206,402.2,205,400.5z
             M208.4,394.3L208.4,394.3l-5.9-3.4l-1.1-0.7l-4.3-2.5c-0.7-2.3-1.1-4.6-1.1-6.7c0-2.3,0.5-4.2,1.3-5.6l4.2,2.4
            c-0.4,1.7-0.7,3.7-0.7,6c0,2.1,0.2,4.3,0.6,6.4l1.1,0.7c-0.4-2.1-0.6-4.3-0.6-6.4c0-2.3,0.3-4.3,0.7-6l5.9,3.4l0,0L208.4,394.3z
             M210.5,405.3c-0.3-0.1-0.7-0.2-1-0.3C209.8,405.2,210.2,405.3,210.5,405.3s0.7,0.1,1,0.1C211.2,405.4,210.9,405.4,210.5,405.3z
             M215.1,399.4L215.1,399.4c-1.1,3.5-3.2,5.3-5.6,4.3v-7.5L215.1,399.4z M209.5,394.9v-12.4l5.9,3.4c0.4,2.2,0.7,4.5,0.7,6.8
            c0,2.2-0.2,4.1-0.6,5.7L209.5,394.9z M212.9,405c1.5-0.7,2.6-2.5,3.4-4.9l4,2.3C218.7,404.7,216,405.7,212.9,405z M222,396
            c0,2.2-0.4,4-1.1,5.4l-4.3-2.5c0.4-1.6,0.6-3.6,0.6-5.7c0-2.3-0.2-4.5-0.6-6.7l4.2,2.4C221.6,391.4,222,393.8,222,396z
             M216.3,385.1l-1.1-0.7l-5.5-3.2l-1.1-0.6l-5.5-3.2l-1.1-0.7l-3.9-2.3c1.6-2.2,4.2-3.1,7.3-2.4c-1.4,0.7-2.6,2.3-3.3,4.7l1.1,0.7
            c1.2-3.4,3.2-5,5.5-4.1v7.3l1.1,0.6v-7.3c2.4,1.8,4.4,5.7,5.5,10.4L216.3,385.1c-0.8-3.2-1.9-6.2-3.3-8.5
            c3.1,2.9,5.6,6.8,7.2,10.8L216.3,385.1z"
                />
              </g>
              <g>
                <path
                  className="st25"
                  d="M317.1,357v9c4-0.7,6.9-4,6.9-4v-9.6C322.1,355.1,317.1,357,317.1,357z"
                />
                <path
                  className="st25"
                  d="M317.1,369.5v9c3.5-0.5,6.9-4,6.9-4v-9.6C322.1,367.7,317.1,369.5,317.1,369.5z"
                />
                <path
                  className="st25"
                  d="M317.1,381.6v9.1c3.5-0.5,6.9-3.7,6.9-3.7v-9.6C321.9,379.8,317.1,381.6,317.1,381.6z"
                />
              </g>
              <g>
                <path
                  className="st25"
                  d="M235,371.6c0,0,5.9,1.9,9.9,2.5v-9c0,0-3.8-0.4-9.9-2.6V371.6z"
                />
                <path
                  className="st25"
                  d="M235,384.2c0,0,6.4,2,9.9,2.5v-9c0,0-4.6-0.2-9.9-2.5V384.2z"
                />
                <path
                  className="st25"
                  d="M235,396.8c0,0,6.4,1.7,9.9,2.2v-9.1c0,0-6.2-0.2-9.9-2.5V396.8z"
                />
              </g>
            </g>
          </g>
          <g id="Ekran_3_1_" ref={InterfaceThree}>
            <path
              className="st35"
              d="M304.8,337.9l0.4,40.5c0,0,24.4-9.7,29.2-21.1v-45C334.2,312.3,329.9,324.8,304.8,337.9z"
            />
          </g>
        </g>
      </g>
    </Svg>
  );
};

export default SvgHome;
